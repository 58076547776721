import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'universal-cookie';
import './index.scss'
import { db } from '../../../Firebase';

import { getDoc, getFirestore, collection, query, where, doc } from "firebase/firestore";
import IMAGES from '../../../Assets/Img';
import CustomImg from '../../../Components/Common/Imgs/customImg';
import AuthenticationContext from '../../../Contexts/AuthConttext';
import Modules from '../../../Components/Globals/NavBar/routes';
import { Loader } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
const SubirFacturas = () => {
    const galletitas = new Cookies()
    const idToken = galletitas.get("id_token", "idToken", { path: '/' })
    const [filesUploaded, setFilesUploaded] = useState([]);
    const [file, setFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false)
    const { scopes, email } = useContext(AuthenticationContext)
    const notifications = useNotifications();
    let relojito
    const permisos = Modules[2].subMenu[0].subMenu[1].roles
    const dondeLePego  =email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'


    //FUNCTIONS
    const timer = () => {
        relojito = setInterval(() => {
            getProcess()
            console.log('disparando')
        }, 1000);
    }
    const uploadBills = () => {
        setIsUploading([])
        const uploadData = new FormData();
        for (const name in file) {
            uploadData.append('x', file[name]);
            setIsUploading([...filesUploaded, file[name]])
        }

        timer()
        fetch(`https://adm.beyondmediaportal.com/facturacion/subir_factura?id_token=${idToken}`, {
            method: 'POST',
            body: uploadData,
            headers: {
                'idToken': idToken,
                'agency': dondeLePego
            }
        },

        )
            .then(res => {
                // getdatafromfs()
                setIsUploading(false)
                clearInterval(relojito)
            })
            .catch(error => {
                console.log(error)
                setIsUploading(false)

            })
    }

    async function getdatafromfs() {
        const docRef = doc(db, "Facturas", "current");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const getData = docSnap.data();
            setFilesUploaded(getData.status)
        } else {

            console.log(docRef)
            console.log("No such document!");
        }
    }
    const chooseFiles = (evt) => {
        setFile([...evt.target.files])
    }


    async function getProcess() {
        const docRef = doc(db, "Facturas", "current");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data()
        const status = data.status
        const running = data.running
        if (filesUploaded && filesUploaded.length === 0) {
            setFilesUploaded(status)
        } else {
            setFilesUploaded([...filesUploaded, ...status])
        }
        
    }


    //USEEFFECTS



    if (scopes && scopes.find(element => element === 'subirFacturas')) {
        return (<>
            <div className='subirFacturasMainA'>
            <div className='subirFacturasMain'>
                <div class="form-group file-area">
                    <input type="file" required multiple onChange={chooseFiles} name='x' accept="application/pdf" />
                    <div class="file-dummy">
                        <div class="success">
                            {file.length === 0 ? null : file.map((data) =>
                                <li>{data.name}</li>
                            )
                            }
                        </div>
                        <div class="default">{file.length === 0 ? <div className='abrirArchivos'><CustomImg {...IMAGES.verFacturas} clase="" /> </div> : null}</div>
                        {file.length === 0 ? <p>Seleccione Archivos</p> : null}
                    </div>
                </div>
                {isUploading ? <Loader color="orange" size={`xl`} /> : <button onClick={() => uploadBills()} className='buttonStandard'>Subir Archivo</button>}
                
                <p>Proovedores Habilitados: </p>
                <div className='divProv'>

                    <p value="Google">Google</p>
                    <p value="Facebook">Facebook</p>
                    <p value="infobae">Infobae</p>
                    <p value="La Nación">La Nación</p>
                    <p value="IMS">IMS</p>



                </div>



                <div class="form-group">

                </div>
   

                </div>
                {filesUploaded.length === 0 ? null : <div className='dataUploadedDiv'>
                    <h4 className='dataUploaded'>Status de archivos subidos:</h4>

                    {filesUploaded.map((data) => <div className='dataUploaded' key={data.name}>
                        <div><p >{data.name}</p></div>
                        <div>                    {data.uploaded ? <CustomImg {...IMAGES.okIcon} clase={'facturasMain__display-conteiner-img'} /> :
                            <CustomImg {...IMAGES.rejectIcon} clase={'facturasMain__display-conteiner-img'} />
                        }</div>
                        <div>                    {data.error ? <p >{data.error}</p> : null}</div>



                    </div>
                    )
                    }
                </div>}
            </div>
  

        </>
        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />

        )

    }

}
export default SubirFacturas 
