import IMAGES from "../../../Assets/Img"
import CustomImg from "../../Common/Imgs/customImg"
import { Home, BrandGoogle, Ballpen,FileDescription,FileUpload, Businessplan,AdjustmentsHorizontal,Pencil } from 'tabler-icons-react';


const Modules = [{
    name: "Home",
    tools: ['campaignBuilder' ,'reporting', 'subirFacturas', 'verFacturas', 'subirFacturasManual', 'management', 'cargarPagos'],
    module: "/",
    icon: <Home  size={25}   strokeWidth={2}  color={'white'} />,
    subMenu: []},
    {
    name: "GMP",
    tools: ['campaignBuilder' ,'reporting'],
    module: "/#",
    icon: <BrandGoogle  size={25}   strokeWidth={2}  color={'white'} />,
    subMenu: [
        {
            name: "C. Manager",
            tools: ['campaignBuilder' ,'reporting',],
            module: "/GMP",
            icon: <CustomImg {...IMAGES.gmpLogo} />,
            subMenu: [
                {
                    name: "Campaign Builder",
                    tools: ['campaignBuilder',],
                    module: "/webcb",
                    icon: <CustomImg {...IMAGES.gmpLogo} />,
                    subMenu: []
                },
                {
                    name: "Reporting",
                    tools: ['reporting'],
                    module: "/cmReporting",
                    icon: <CustomImg {...IMAGES.gmpLogo} />,
                    subMenu: []
                }
            ]
        }
    ]
},
{
    name: "Administracion",
    tools: [ 'subirFacturas', 'verFacturas', 'subirFacturasManual', 'management', 'cargarPagos'],
    module: "/Administracion",
    icon:  <Ballpen  size={25}   strokeWidth={2}  color={'white'} />,
    subMenu: [
        {
            name: "Facturas",
            tools: [ 'subirFacturas', 'verFacturas', 'subirFacturasManual','cargarPagos'],
            module: "/Facturas",
            icon: <FileDescription  size={25}   strokeWidth={2}  color={'white'} />,
            subMenu: [
                {
                    name: "Subir Facturas Manual",
                    tools: ['subirFacturasManual'],
                    module: "/subirFacturasManual",
                    icon: <FileUpload  size={25}   strokeWidth={2}  color={'white'} />,
                    subMenu: []
                },
                {
                    name: "Subir Facturas",
                    tools: ['subirFacturas'],
                    module: "/subirFacturas",
                    icon: <FileUpload  size={25}   strokeWidth={2}  color={'white'} />,
                    subMenu: []
                },
                {
                    name: "Ver Facturas",
                    tools: ['verFacturas'],
                    module: "/verFacturas",
                    icon: <FileDescription  size={25}   strokeWidth={2}  color={'white'} />,
                    subMenu: []
                }
            ]
        },
        {
            name: "Management",
            tools: [ 'management'],
            module: "/management",
            icon: <AdjustmentsHorizontal  size={25}   strokeWidth={2}  color={'white'} />,
            subMenu: []
        },
        {
            name: "Management 2",
            tools: [ 'management2'],
            module: "/management2",
            icon: <AdjustmentsHorizontal  size={25}   strokeWidth={2}  color={'white'} />,
            subMenu: []
        },
    ]
},
{
    name: "Planning",
    tools: ['ops'],
    module: "/Administracion",
    icon: <Businessplan  size={25}   strokeWidth={2}  color={'white'} />,
    subMenu: [
        {
            name: "Ops",
            tools: ['ops'],
            module: "/ops",
            icon: <Pencil  size={25}   strokeWidth={2}  color={'white'} />,
            subMenu: []
        },
    ]
}
]

export default Modules