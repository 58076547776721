import  isologotipo from "./isologotipo.png"
import  isologotipoBlack from "./isologotipoBlack.png"
import iso from "./iso.png"
import isolo from "./isolo.png"
import userIcon from "./userIcon.png"
import logout from "./logout.png"
import flechaArriba from "./arrowArriba.png"
import flechaAbajo from "./arrowAbajo.png"
import configOn from './config_on.png'
import configOff from './config_off.png'
import config from './config.png'
import menuOpen from './menu_open.png'
import menuClose from './menu_close.png'
import homeIcon from "./home.png"
import gmpLogo from "./gmpLogo.png"
import dvLogo from "./dvLogo.png"
import cmLogo from "./cmLogo.png"
import adminLogo from "./adminLogo.png"
import subirFacturas from "./subirFacturas.png"
import bajarFacturas from "./bajarFacturas.png"
import verFacturas from "./verFacturas.png"
import okIcon from "./ok.png"
import rejectIcon from "./rejectIcon.png"
import isoLoader from "./isoLoader.gif"
import fondo from "./fondo.png"
import lupita from "./lupita.png"
import ver from "./ver.png"
import logotipo from "./logotipo.png"
import comentario from "./comentario.png"
import volverAtras from "./volverAtras.png"
import close from "./close.png"
import trash from "./delete.png"
import teco from "./tecoLogo.png"
const IMAGES = {
    trash: {
        img: trash,
        name :'trash',
    },
    teco: {
        img: teco,
        name :'teco',
    },
    isologotipo: {
        img: isologotipo,
        name :'isologotipo',
    },
    close: {
        img: close,
        name :'close',
    },
    volverAtras: {
        img: volverAtras,
        name :'volverAtras',
    },
    logotipo: {
        img: logotipo,
        name :'logotipo',
    },
    ver: {
        img: ver,
        name :'ver',
    },
    comentario  : {
        img: comentario ,
        name :'comentario   ',
    },
    isologotipoBlack: {
        img: isologotipoBlack,
        name :'isologotipoBlack',
    },
    isologo: {
        img: iso,
        name :'isologo',
    },
    isologolo: {
        img: isolo,
        name :'isologo',
    },
    userIcon: {
        img: userIcon,
        name :'isologo',
    },
    logout: {
        img: logout,
        name :'logout',
    },
    flechaArriba: {
        img: flechaArriba,
        name :'logout',
    },
    flechaAbajo: {
        img: flechaAbajo,
        name :'logout',
    },
    configOn: {
        img: configOn,
        name :'config',
    }
    ,
    configOff: {
        img: configOff,
        name :'config',
    },
    config: {
        img: config,
        name :'config',
    },
    menuOpen: {
        img: menuOpen,
        name :'config',
    }
    ,
    menuClose: {
        img: menuClose,
        name :'config',
    },
    homeIcon: {
        img: homeIcon,
        name :'config',
    },
    gmpLogo: {
        img: gmpLogo,
        name :'config',
    }
    ,
    dvLogo: {
        img: dvLogo,
        name :'config',
    },
    cmLogo: {
        img: cmLogo,
        name :'config',
    },
    adminLogo: {
        img: adminLogo,
        name :'config',
    },
    subirFacturas: {
        img: subirFacturas,
        name :'config',
    },
    bajarFacturas: {
        img: bajarFacturas,
        name :'config',
    },
    verFacturas: {
        img: verFacturas,
        name :'config',
    },
    okIcon: {
        img: okIcon,
        name :'config',
    },
    isoLoader: {
        img: isoLoader,
        name :'config',
    },
    rejectIcon: {
        img: rejectIcon,
        name :'config',
    },
    fondo: {
        img: fondo,
        name :'fondo',
    },
    lupita: {
        img: lupita,
        name :'fondo',
    }


}
export default IMAGES