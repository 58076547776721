import React, { useEffect, useContext, useState } from 'react'
import { authentication } from '../../Firebase'
import { signInWithPopup, GoogleAuthProvider, browserLocalPersistence, setPersistence, inMemoryPersistence } from 'firebase/auth'
import { onSnapshot, collection, doc, query, orderBy , limit, startAfter,where} from "firebase/firestore";
import AuthenticationContext from '../../Contexts/AuthConttext';
import { useNavigate } from 'react-router-dom';
import IMAGES from '../../Assets/Img';
import CustomImg from '../../Components/Common/Imgs/customImg';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module'
import { db } from '../../Firebase';
import {getDoc, setDoc, getFirestore, updateDoc} from "firebase/firestore";

import "./index.scss"
const styles = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

}



const Login = (e) => {
    const navigate = useNavigate()
    const { setAuthenticated, usuario, setUsuario, setPhoto, setEmail, setIdToken , scopes, setScopes,email} = useContext(AuthenticationContext)    
    const cookies = new Cookies();


 
 
//FUNCTIONS

      function signOut() {
        authentication .signOut().then(function() {
          cookies.remove("id_token")
          cookies.remove("loggedIn")
          cookies.remove("scopes")
          cookies.remove("email")
          document.location.reload(true)
        }).catch(function(error) {
          console.error(error)
        })
      }

    function signIn() {
      const provider = new GoogleAuthProvider()
      provider.addScope("https://www.googleapis.com/auth/drive")
      provider.addScope('https://www.googleapis.com/auth/dfatrafficking')
      provider.addScope('https://www.googleapis.com/auth/dfareporting')
      provider.addScope("https://www.googleapis.com/auth/display-video")
      provider.addScope('https://www.googleapis.com/auth/doubleclickbidmanager')
      const idToken = cookies.get("loggedIn", "true", { path: '/' })
        signInWithPopup(authentication, provider)
        .then(function(authData) {
            if (authData.user.email.includes("@beyondmediaagency.com") || authData.user.email.includes("@digitalsoul.agency")) {
                const data = {
                    "email": authData.user.email,
                    "name": authData.user.displayName,
                    "photo": authData.user.photoURL,
                    "uid": authData.user.uid,
                    "role": "",
                    "createdAt": authData.user.metadata.creationTime,
                    "lastLogin": authData.user.metadata.lastSignInTime
                  }
                  authData.user.getIdToken().then(function(idToken) {
                    cookies.set("id_token", idToken, { path: '/' })
                    setIdToken(idToken)
                  }).catch(function(error) {
                    // Handle error
                    console.error(error)
                  });
                  setEmail(data.email)
                  setPhoto(data.photo)
                  setUsuario(data.name)
                  
                  cookies.set("displayName", data.name)
                  cookies.set("email", data.email)
                  cookies.set("photoUrl", data.photo)
                  console.log("redirecting to /home")
        
                  if (authData.user.email.includes("@beyondmediaagency.com") || authData.user.email.includes("@digitalsoul.agency")) {
                    navigate("/", { replace: true })}
                  
                  
            }
            else{
                alert('cuenta invalida')
                signOut()
                
            }


            
        }).catch(function(error) {
          console.error(error);
        })
      }



    
    

    return (
        <div style={styles} className="loginPage">
            <div className="loginPage__hero">
                <CustomImg {...IMAGES.logotipo} clase="loginPage__hero-logo" />
            </div>
            <div className="loginPage__login">
                <div className="loginPage__login-welcome">
                    <CustomImg {...IMAGES.isologolo} clase="loginPage__login-welcome-logo" />
                </div>
                <div className="loginPage__login-divForm">
                    <button className="loginPage__login-divForm-button" onClick={signIn}>Login</button>
                </div>
            </div>

        </div>
    )
}

export default Login
