import React,{useContext} from 'react'
import { Navigate } from "react-router-dom"
import AuthenticationContext from '../../Contexts/AuthConttext';




const PrivateRoute = ({ children }) => {
  const {authenticated} =useContext(AuthenticationContext)
    return authenticated ? children : <Navigate to="/" />;
  };

export default PrivateRoute