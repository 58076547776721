import React, { useEffect, useContext, useState } from 'react'
import Cookies from 'universal-cookie';
import AuthenticationContext from '../../../Contexts/AuthConttext';
import { db } from '../../../Firebase';
import Modules from '../../../Components/Globals/NavBar/routes';
import { getDocs, getFirestore, collection, where, query, limit, startAfter, onSnapshot } from "firebase/firestore";
import { useNotifications } from '@mantine/notifications';
import { Loader } from '@mantine/core';
import './index.scss'
import { X } from 'tabler-icons-react';
import { Transition } from '@mantine/core';
const ModalCargarComp = ({ handleDisplayC, id, name,fn,fn2 }) => {
    const { scopes, email } = useContext(AuthenticationContext)
    const dondeLePego = email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
    const [newPDF, setNewPDF] = useState(undefined);
    const [lastSelected, setLastSelected] = useState(undefined);

    const [idFC, setIdFC] = useState(id);
    const [selectedFC, setSelectedFC] = useState(name);
    const [mailsToSend, setMailsToSend] = useState([]);
    const [joinedEmail, setJoinedEmail] = useState('');
    const [isUploaded, setIsUploaded] = useState(true);
    const notifications = useNotifications();

    const cargarPago = () => {
        const target_1 = `https://adm.beyondmediaportal.com/facturacion/pago_facturas?id_factura=${id.join(',')}&mails=${mailsToSend.join(',')}`;

        const galletitas = new Cookies()
        const idToken = galletitas.get("id_token")
        setIsUploaded(false)

        const uploadData = new FormData();
        uploadData.append('x', newPDF);

        fetch(target_1, {
            method: "POST",
            mode: "cors",
            body: uploadData,
            headers: {
                "idToken": idToken,
                "agency": dondeLePego,
            },
        }
        )
            .then(response => {
                response.json()
                if (response.status === 200) {
                    notifications.showNotification({
                        title: 'Comprobante cargado y enviado!!',
                        message: `El comprobante de la factura ${selectedFC} fue correctamente cargado! `,
                        color: 'green',
                        autoClose: 5000,
                    })
                    setIsUploaded(true)
                    fn([])
                    fn2([])
                } else {
                    notifications.showNotification({
                        title: 'Error en la carga',
                        message:`El comprobante de la factura ${selectedFC} no fue cargado! `,
                        color: 'red',
                        autoClose: 5000,
                    })
                    setIsUploaded(true)
                 
                }
            })
            .catch((e) => {
                console.log(e)
                notifications.showNotification({
                    title: 'Error en la carga',
                    message: `El comprobante de la factura ${selectedFC} no fue cargado! `,
                    color: 'red',
                    autoClose: 5000,
                })
                setIsUploaded(true)
            })


    }



    const agregarMail = () => {
        setMailsToSend([...mailsToSend, joinedEmail])
        setJoinedEmail('')
    }



    const validateMail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/



    const onSubmitMail = (e) => {
        const a = validateMail.test(e.target.value)
        if (e.keyCode === 13 & a) {
            // Cancel the default action, if needed
            e.preventDefault();
            // Trigger the button element with a click
            setMailsToSend([...mailsToSend, joinedEmail])
            setJoinedEmail('')
            e.target.value = ''
        } else if (e.keyCode === 13 & !a) {
            notifications.showNotification({
                title: 'Email no valido',
                message: `Ingresaste un formato invalido de mail`,
                color: 'red',
                autoClose: 5000,
            })
        }

    }



    return (<>


        <div className='cargaPagosModal'>
            {idFC ?

                <div className="cargaPagosModal__display-content">
                    <div className='exitButton'>
                        <X

                            size={30}
                            strokeWidth={3}
                            color={'#fafafa'}
                            onClick={handleDisplayC}
                        />
                    </div>

                    <div className="cargaPagosModal__display-content-title">

                        <p className="cargaPagosModal__display-content-data-dato">Usted selecciono la factura: <span> {selectedFC.join(', ')}</span> </p>
                    </div>

                    <div className="cargaPagosModal__display-content-emails">
                        <label >Escriba los mails a donde desea enviar el comprobante</label>
                        <input type="text" onInput={(e) => validateMail.test(e.target.value) ? setJoinedEmail(e.target.value) : null} onFocus={(e) => e.target.value = ''} onKeyDown={(e) => onSubmitMail(e)} className="ops__Conteiner-membrete-data-row-inputDiv-input" />
                        <p>Mails agregados:</p>
                        <ol>
                            {mailsToSend.map(x => <li>{x}           <X
                                size={30}
                                strokeWidth={3}
                                color={'red'}

                                onClick={() => setMailsToSend(mailsToSend.filter(t => t !== x))}
                            />  </li>)}

                        </ol>
                        <button onClick={() => setMailsToSend([])} className='buttonStandard pointer'>limpiar mails</button>
                    </div>
                    <div className="cargaPagosModal__display-content-buttons">

                        <div className='file-input  fit'>
                            <label for="size_2" >{!newPDF ? 'Seleccione Archivo' : newPDF.name}
                                <input onChange={(e) => setNewPDF(e.target.files[0])} type="file" name="file" id="file" className='inputfile  fit' /></label>

                        </div>


                    </div>
                    <div className="cargaPagosModal__display-content-subir">
                        {isUploaded ? <button className='buttonStandard pointer' onClick={cargarPago} > Cargar Pago</button> : <Loader color="orange" size="sm" />}
                    </div>

                </div>




                : <div className="cargaPagosModal__display-content">
                    <div className="cargaPagosModal__display-content-data">
                    </div>



                </div>


            }
        </div>

    </>

    )


}

export default ModalCargarComp