import React,{useContext} from "react"
import Package from "./Package"
import Cookies from "universal-cookie"
import AuthenticationContext from '../../../Contexts/AuthConttext';
function Site({name, packs, campaignId, profileId, advertiserName, parentCallback, edit, siteName, toggleEditRow,documentId}) {
    const arrPacks = []
    const arrObjPacks = []
    for (const pack in packs) {
        arrObjPacks.push(packs[pack])
        arrPacks.push(pack)
    }
    const {email} = useContext(AuthenticationContext)


    const dondeLePego = email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
    const siteNamee = arrObjPacks[0].placements[Object.keys(arrObjPacks[0].placements)[0]].SiteName
    const truncate = (input) => input.length > 30 ? `${input.substring(0, 30)}...` : input;
    const cookies = new Cookies()
    const idToken = cookies.get("id_token", "idToken", { path: '/' })
    return (
        <>
            <tr key={name + "trid"}  className='CBConteiner__tablePlacement-body-row' >
                <td className='CBConteiner__tablePlacement-body-row-td'>
                    {siteNamee+ " - " + name}
                </td>
                <td className='CBConteiner__tablePlacement-body-row-td sitetag'>
                <form action={`https://api.beyondmediaportal.com/gapi/cb_web/generate-tags-site`} target="_blank" >
                        <input type="hidden" value={idToken} name="id_token"></input>
                        <input type="hidden" value={campaignId} name="campaignId"></input>
                        <input type="hidden" value={profileId} name="profileId"></input>
                        <input type="hidden" value={advertiserName} name="advertiserName"></input>
                        <input type="hidden" value={name} name="siteId"></input>
                        <input type="hidden" value={siteNamee} name="siteName"></input>
                        <input type="hidden" value={documentId} name="documentId"></input>
                        <input type="hidden" value={dondeLePego} name="agency"></input>
                        <input type="submit" value="Site tags" className="buttonStandard"></input>
                    </form>

                </td>
                <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>

                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
            </tr>
            {arrPacks.map((pack) => {
                return (
                    <Package toggleEditRow={toggleEditRow} name={truncate(pack)} campaignId={campaignId} profileId={profileId} advertiserName={advertiserName} placements={packs[pack]["placements"]} parentCallback={parentCallback} edit={edit} key={pack} documentId={documentId}/>
                )
            })}
        </>
    )
}
export default Site