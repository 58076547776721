import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";

import Login from "../../Pages/Login";

import Error404 from "../../Pages/Error404";

import ModalText from "../../Components/Common/ModalText";
import ModalContext from "../../Contexts/ModalContext";
import AuthenticationContext from "../../Contexts/AuthConttext";

import WebCB from "../../Pages/WebCB/index";
import Document from "../../Pages/WebCB/WebCBDocs/Document";
import UserNav from "../../Components/Globals/UserNav";
import CMReporting from "../../Pages/CMReporting";
import Home from "../../Pages/Home";
import VerFacturas from "../../Pages/Facturas/VerFacturas";
import Ops from "../../Pages/Ops";
import SubirFacturasManual from "../../Pages/Facturas/SubirFacturasManual";
import SubirFacturas from "../../Pages/Facturas/SubirFacturas";
import Management from "../../Pages/Management";
import NewNavbar from "../../Components/Globals/NavBar/newNavbar";
import ManagementDos from "../../Pages/ManagementDos";
import Profile from "../../Pages/ManagementDos/Profiles";
import Chatbot from "../../Components/Globals/Chatbot";

const Pages = [
    {
        component: <WebCB />,
        path: "/webcb",
    },
    {
        component: <ManagementDos />,
        path: "/management",
    },
    {
        component: <Profile />,
        path: "/management/:profileId",
    },
    {
        component: <Management />,
        path: "/management2",
    },
    {
        component: <CMReporting />,
        path: "/cmReporting",
    },
    {
        component: <Home />,
        path: "/",
    },
    {
        component: <VerFacturas />,
        path: "/verFacturas",
    },
    {
        component: <SubirFacturas />,
        path: "/subirFacturas",
    },
    {
        component: <Ops />,
        path: "/ops",
    },
    {
        component: <SubirFacturasManual />,
        path: "/subirFacturasManual",
    },
    {
        component: <Document />,
        path: "/webcb/doc/:id",
    },
];

const Routing = () => {
    const { authenticated } = useContext(AuthenticationContext);
    const { showModal } = useContext(ModalContext);
    return (
        <>
            <div id={authenticated ? "cuerpo" : null}>
                {showModal ? <ModalText /> : null}
                <Router>
                    <div id={authenticated ? "userNav" : null}>
                        {authenticated ? <UserNav /> : null}
                    </div>
                    <div id={authenticated ? "navBar" : null}>
                        {authenticated ? <NewNavbar /> : null}
                    </div>
                    <div id={authenticated ? "contenido" : null}>
                        <Routes>
                            {Pages.map((x) => (
                                <Route
                                    exact
                                    path={x.path}
                                    key={x.path}
                                    element={<PrivateRoute>{x.component}</PrivateRoute>}
                                ></Route>
                            ))}
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                    </div>
                </Router>
            </div>
        </>
    );
};

export default Routing;
