import React, { useState, useContext, useEffect } from "react";
import IMAGES from "../../../Assets/Img";
import { useNavigate } from "react-router-dom";
import ModalUpdateFc from "../../Common/ModalUpdateFc";
import "./index.scss";
import AuthenticationContext from "../../../Contexts/AuthConttext";
import DropDown from "./DropDown";
import Cookies from "universal-cookie";
import { authentication } from "../../../Firebase";
import { getAuth, signOut } from "firebase/auth";
const UserNav = () => {
    const { setAuthenticated, usuario, photo } = useContext(
        AuthenticationContext
    );
    const [arrow, setArrow] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const cookies = new Cookies();
    const handleShow = () =>
        showModal ? setShowModal(false) : setShowModal(true);
    const handleClick = (x) => {
        if (x == 1) {
            setArrow(true);
        } else {
            setArrow(false);
        }
    };

    const handleClick2 = (x) => {
        setArrow(!arrow);
    };
    const navigate = useNavigate();

    function signOut() {
        authentication
            .signOut()
            .then(function() {
                cookies.remove("id_token");
                cookies.remove("loggedIn");
                cookies.remove("scopes");
                cookies.remove("email");
                document.location.reload(true);
            })
            .catch(function(error) {
                console.error(error);
            });
    }

    return (
        <>
            <div className="userNav">
                <div className="avatar">
                    <img
                        src={photo}
                        className="userNav-avatar-initials"
                        onMouseDown={handleClick}
                    />
                </div>
                {arrow ? null : (
                    <DropDown
                        logout={signOut}
                        fn={() => handleClick2(1)}
                        fn2={handleShow}
                    />
                )}
            </div>
            {showModal ? (
                <ModalUpdateFc handleShow={handleShow} operation={"reportBug"} />
            ) : null}
        </>
    );
};

export default UserNav;
