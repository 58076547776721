import React, { useEffect, useState, useContext } from 'react'
import { db } from '../../Firebase';
import { getDocs, getFirestore, collection, getDoc, doc, updateDoc, onSnapshot, query } from "firebase/firestore";
import './index.scss'
import AuthenticationContext from '../../Contexts/AuthConttext';
import ModalContext from '../../Contexts/ModalContext';
import Modules from '../../Components/Globals/NavBar/routes';
import { Loader } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { Check } from 'tabler-icons-react';
const Management = () => {
    const [emailList, setEmailList] = useState([]);
    const [filteredEmailList, setFilteredEmailList] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const notifications = useNotifications();
    const [aviableScopes, setAviableScopes] = useState([]);
    const { scopes } = useContext(AuthenticationContext);
    const [selectedEmail, setSelectedEmail] = useState(undefined);
    const { showModal, setShowModal, text, setText } = useContext(ModalContext)
    const [isUploaded, setIsUploaded] = useState(true);
    const [actualScope, setActualScope] = useState([])
    const tools = ['campaignBuilder', 'reporting', 'subirFacturas', 'verFacturas', 'subirFacturasManual', 'management', 'cargarPagos', 'ops']
    const [scopeUpdate, setScopeUpdate] = useState([]);
    const handleSearchLetter = (event) => {
        setEmailSearch(event.target.value)
    };

    const [newScope, setNewScope] = useState('');
    const permisos = Modules[2].subMenu[1].roles


    const emailFilter = () => setFilteredEmailList(emailList.filter((e) => e.includes(emailSearch)))

    async function getdatafromfs() {
        const q = query(collection(db, "Users"))
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {

            console.log(querySnapshot)
            const getemails = querySnapshot.docs
                .map(doc => doc.id);
            setEmailList(getemails)

            const source = querySnapshot.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
        })
    }
    async function getUserFs() {
        setScopeUpdate([])
        setActualScope([])
        const docRef = doc(db, "Users", selectedEmail)
        const docSnap = await getDoc(docRef);
        const getData = docSnap.data()
        setActualScope(getData.tools)


    }
    async function getScopes() {
        setScopeUpdate([])
        setActualScope([])
        const docRef = doc(db, "Scopes", 'root')
        const docSnap = await getDoc(docRef);
        const getData = docSnap.data()
        setAviableScopes(getData.scopes)


    }




    useEffect(() => {
        getdatafromfs()
        getScopes()
    }, []);
    useEffect(() => {

        emailFilter()

    }
        , [emailSearch]);

    useEffect(() => {
        if (selectedEmail) {
            getUserFs()
        }

    }, [selectedEmail])


    const refreshScope = () => {
        updateScope()

    }

    const handleScope = (event) => {
        setNewScope(event.target.value)
    }

    const managetools = (x) => {
        if (actualScope) {
            if (actualScope.find(i => (i === x))) {
                setScopeUpdate(actualScope.filter((element) => element != x))
                setActualScope(actualScope.filter((element) => element != x))
            } else {
                setScopeUpdate([...actualScope, x])
                setActualScope([...actualScope, x])
            }

        } else {
            if (scopeUpdate.length == 0) {
                setScopeUpdate([x])
                setActualScope([x])
            } else {
                if (scopeUpdate.find(i => (i === x))) {
                    setScopeUpdate(scopeUpdate.filter((element) => element != x))
                } else {
                    setScopeUpdate([...scopeUpdate, x])
                    setActualScope([...scopeUpdate, x])

                }

            }

        }

    }



    const updateScope = async () => {
        setIsUploaded(false)
        const emailRef = doc(db, "Users", selectedEmail);
        await updateDoc(emailRef, {
            tools: scopeUpdate
        });
        notifications.showNotification({
            title: 'Scope actualizado!!',
            message: `Las tools han sido modificadas`,
            color: 'green',
            autoClose: 5000,
        })
        setIsUploaded(true)
    }

    if (scopes && scopes.find(element => element === 'management')) {
        return (
            <div className='management'>
                <div className='managementDiv'>
                    <div className='management__divEmails'>
                        <div className='management__divEmails-header'>
                            <p>Mail Empleados</p>
                            <label > Buscar Empleado
                                <input type="text" onInput={handleSearchLetter} />
                            </label>
                        </div>
                        <div className='management__divEmails-body'>
                            <ul className='management__divEmails-body-list'>
                                {filteredEmailList.length > 0 ?
                                    filteredEmailList.map(x => <li className='management__divEmails-body-list-item pointer' onClick={() => setSelectedEmail(x)} >{x}</li>)
                                    :
                                    emailList.map(x => <li className='management__divEmails-body-list-item pointer' onClick={() => setSelectedEmail(x)} >{x}</li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='management__divData'>
                        {selectedEmail ? <>
                            <label className='management__divData-label'>
                                Seleccione las tools que desea otorgar a {selectedEmail}:
                            </label>

                            <div className='divButtonsTools'>

                                {actualScope && aviableScopes ?
                                    aviableScopes.map(x =>
                                        <div>
                                            <label className=''>{x}</label>
                                            <button onClick={() => managetools(x)} className="buttonCheck">
                                                {actualScope.find(i => (i === x)) ? <Check size={18} strokeWidth={5} color={'#FFB60B'} /> : ''}
                                            </button>
                                        </div>
                                    )
                                    : aviableScopes.map(x =>
                                        <div>
                                            <label className=''>{x}</label>
                                            <button onClick={() => managetools(x)} className="buttonCheck">
                                                {scopeUpdate.find(i => (i === x)) ? <Check size={18} strokeWidth={5} color={'#FFB60B'} /> : ''}
                                            </button>
                                        </div>
                                    )}
                            </div>


                            {isUploaded ? <button onClick={() => refreshScope()} className='buttonStandard'>Actualizar</button> : <Loader color="orange" size={`sm`} />}
                        </>

                            :

                            null}
                    </div>

                </div>

            </div>
        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />
        )

    }
}
export const ManagementEmbed = (props) => {
    const [emailList, setEmailList] = useState([]);
    const [filteredEmailList, setFilteredEmailList] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const notifications = useNotifications();
    const [aviableScopes, setAviableScopes] = useState([]);
    const { scopes } = useContext(AuthenticationContext);
    const { showModal, setShowModal, text, setText } = useContext(ModalContext)
    const [isUploaded, setIsUploaded] = useState(true);
    const [actualScope, setActualScope] = useState([])
    const tools = ['campaignBuilder', 'reporting', 'subirFacturas', 'verFacturas', 'subirFacturasManual', 'management', 'cargarPagos', 'ops']
    const [scopeUpdate, setScopeUpdate] = useState([]);
    let [isUserCreated, setIsUserCreated] = useState(false);
    const handleSearchLetter = (event) => {
        setEmailSearch(event.target.value)
    };

    const [newScope, setNewScope] = useState('');
    const permisos = Modules[2].subMenu[1].roles

    const selectedEmail = props.mail_beyond;

    const emailFilter = () => setFilteredEmailList(emailList.filter((e) => e.includes(emailSearch)))

    async function getdatafromfs() {
        const q = query(collection(db, "Users"))
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {

            console.log(querySnapshot)
            const getemails = querySnapshot.docs
                .map(doc => doc.id);
            setEmailList(getemails)

            const source = querySnapshot.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
        })
    }
    async function getUserFs() {
        setScopeUpdate([])
        setActualScope([])
        const docRef = doc(db, "Users", selectedEmail)
        const docSnap = await getDoc(docRef);
        const getData = docSnap.data();

        if (!getData || !getData.tools) {
            alert("El usuario no tiene una cuenta de beyondmediaportal.com")
            setActualScope([])
            setIsUserCreated(false)
            return;
        }
        setIsUserCreated(true);
        setActualScope(getData.tools);
    }
    async function getScopes() {
        setScopeUpdate([])
        setActualScope([])
        const docRef = doc(db, "Scopes", 'root')
        const docSnap = await getDoc(docRef);
        const getData = docSnap.data()
        setAviableScopes(getData.scopes)
    }

    useEffect(() => {
        getScopes();
        getUserFs();
    }, []);
    useEffect(() => {

        emailFilter()

    }, [emailSearch]);



    const refreshScope = () => {
        updateScope()

    }

    const handleScope = (event) => {
        setNewScope(event.target.value)
    }

    const managetools = (x) => {
        if (actualScope) {
            if (actualScope.find(i => (i === x))) {
                setScopeUpdate(actualScope.filter((element) => element != x))
                setActualScope(actualScope.filter((element) => element != x))
            } else {
                setScopeUpdate([...actualScope, x])
                setActualScope([...actualScope, x])
            }

        } else {
            if (scopeUpdate.length == 0) {
                setScopeUpdate([x])
                setActualScope([x])
            } else {
                if (scopeUpdate.find(i => (i === x))) {
                    setScopeUpdate(scopeUpdate.filter((element) => element != x))
                } else {
                    setScopeUpdate([...scopeUpdate, x])
                    setActualScope([...scopeUpdate, x])

                }

            }

        }

    }



    const updateScope = async () => {
        setIsUploaded(false)
        const emailRef = doc(db, "Users", selectedEmail);
        if (isUserCreated) {
            await updateDoc(emailRef, {
                tools: scopeUpdate
            });    
        } else {
            await updateDoc(emailRef, {
                tools: scopeUpdate
            });

        }
        notifications.showNotification({
            title: 'Scope actualizado!!',
            message: `Las tools han sido modificadas`,
            color: 'green',
            autoClose: 5000,
        })
        setIsUploaded(true)
    }

    if (scopes && scopes.find(element => element === 'management')) {
        return (
                <div className='managementDiv'>
                    <div className='management__divData'>
                        <>
                            <label className='management__divData-label'>
                                Seleccione los accesos que desea otorgar a {selectedEmail}:
                            </label>

                            <div className='divButtonsTools'>

                                {actualScope && aviableScopes ?
                                    aviableScopes.map(x =>
                                        <div>
                                            <label className=''>{x}</label>
                                            <button onClick={() => managetools(x)} className="buttonCheck">
                                                {actualScope.find(i => (i === x)) ? <Check size={18} strokeWidth={5} color={'#FFB60B'} /> : ''}
                                            </button>
                                        </div>
                                    )
                                    : aviableScopes.map(x =>
                                        <div>
                                            <label className=''>{x}</label>
                                            <button onClick={() => managetools(x)} className="buttonCheck">
                                                {scopeUpdate.find(i => (i === x)) ? <Check size={18} strokeWidth={5} color={'#FFB60B'} /> : ''}
                                            </button>
                                        </div>
                                    )}
                            </div>


                            {isUploaded ? <button onClick={() => refreshScope()} className='buttonStandard'>Actualizar</button> : <Loader color="orange" size={`sm`} />}
                        </>

                    </div>

                </div>

        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />
        )

    }
}
export default Management