import React, { useEffect, useState } from 'react';
import CustomImg from '../../../../Components/Common/Imgs/customImg';
import IMAGES from '../../../../Assets/Img';


const ModalDays = ({ handleShow, props, days, updateDays }) => {
    const [selectedDays, setSelectedDays] = useState({ ...days });
    const [localMonth, setLocalMonth] = useState('Enero')
    const [isEditing, setIsEditing] = useState(false);
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const totalDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    const handleEdit = () => setIsEditing(!isEditing)
    const selectAll = () => setSelectedDays({ ...selectedDays, [localMonth]: [...totalDays] })
    const clearAll = () => setSelectedDays({ ...selectedDays, [localMonth]: [] })

    const handleSelectedDays = (e) => {
        if (selectedDays[localMonth] && selectedDays[localMonth].find(element => element === e)) {
            setSelectedDays({ ...selectedDays, [localMonth]: [...selectedDays[localMonth].filter(x => x != e)].sort(function (a, b) { return a - b; }) })
        } else {
            setSelectedDays({ ...selectedDays, [localMonth]: [...selectedDays[localMonth], e].sort(function (a, b) { return a - b; }) })
        }
    }

    useEffect(() => {

        return () => {
            setSelectedDays('')
        };
    }, []);
    const refreshDays = () => {
        updateDays(props, selectedDays)
        handleShow()
    }
    if (!isEditing) {
        return (
            <>
                {/* .map((t,k) => console.log(t)) */}
                <div className='cargaPagosModal'>
                    <div className='modalDiv'>
                        <div className='modalDiv__figcaption'>
                            <CustomImg {...IMAGES.adminLogo} clase={'modalDiv__figcaption-img'} />
                        </div>
                        <div className='daysConteinerNoE'>
                            {/* {selectedDays && selectedDays.forEach((data) =>  console.log(data) ) }  */}

                            {selectedDays && Object.entries(selectedDays).map(x => x[1].length > 0 && <p className='totalDiasOpsMes'>{x[0]}: {x[1].join(', ')}</p>)}
                        </div>
                        <div className='divDaysbutt'>
                            <p className='buttonStandard' onClick={handleEdit}>Editar</p>
                            <p className='buttonStandard' onClick={refreshDays}>Cerrar</p>
                        </div>
                    </div>
                </div>

            </>)
    } else {
        return (
            <>
                <div className='cargaPagosModal'>
                    <div className='modalDiv'>
                        <div className='modalDiv__figcaption'>
                            <CustomImg {...IMAGES.adminLogo} clase={'modalDiv__figcaption-img'} />
                        </div>
                        <div className='dayMonthOp'>
                            <div className='daysConteiner'>

                                {totalDays.map((data) => <>
                                    <label className='daysConteiner-label' key={Math.random()}>{data}
                                        <input type="checkbox" key={Math.random()} checked={selectedDays[localMonth] && selectedDays[localMonth].find(x => x === data)} onChange={(e) => handleSelectedDays(parseInt(e.target.value))} value={data}></input></label>
                                </>
                                )}

                            </div>
                            <div className='monthConteiner'>
                                <label>Seleccione un Mes</label>
                                <select defaultValue={localMonth} onChange={(e) => setLocalMonth(e.target.value)}>
                                    {meses.map((data) => <option value={data} key={data}>{data}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className='divDaysbutt'>
                            <p className='buttonStandard' onClick={handleEdit}>Volver Atras</p>
                            <p className='buttonStandard' onClick={selectAll}>Seleccionar Todos</p>
                            <p className='buttonStandard' onClick={clearAll}>Limpiar</p>
                            <p className='buttonStandard' onClick={refreshDays}>Cerrar</p>
                        </div>

                    </div>
                </div>
                <p>Antes Seleccionaste los dias:  </p>
            </>)
    }



};

export default ModalDays
