import React, { useState, useContext, useRef, useEffect } from "react";
import { Logout, MessageCode, AdjustmentsAlt } from "tabler-icons-react";
import AuthenticationContext from "../../../../Contexts/AuthConttext";
import Cookies from "universal-cookie";

const DropDown = ({ logout, fn, fn2 }) => {
    const cookies = new Cookies();
    const idToken = cookies.get("id_token", "idToken");
    const [configStatus, setConfigStatus] = useState(false);
    const { setAuthenticated, usuario, email, photo } = useContext(
        AuthenticationContext
    );
    const confifStatus = () =>
        configStatus ? setConfigStatus(false) : setConfigStatus(true);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    fn(2);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <>
            <div className="userNav__dropDown" ref={wrapperRef}>
                <div className="userNav__dropDown-avatar">
                    <img src={photo} className="userNav__dropDown-avatar-initials" />
                </div>
                <p className="userNav__dropDown-name">{usuario}</p>
                <p className="userNav__dropDown-email">{email}</p>

                <p className="buttonStandard" onClick={fn2}>
                    Ticket Devs
                </p>

                <a
                    href={`https://administracion-m6tezzldla-uc.a.run.app/facturacion/facturas?id_token=${idToken}`}
                    className="buttonStandard"
                    onClick={() => console.log("Te dormimos los datos")}
                    target="_blank"
                >
                    Autenticar Back
                </a>
                <p className="buttonIcon" onClick={logout}>
                    <Logout size={20} strokeWidth={2} color={"white"} />
                </p>
            </div>
        </>
    );
};

export default DropDown;
