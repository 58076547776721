import React from "react"
import Cookies from "universal-cookie"
import Placement from "./Placement"

function Package({name, placements, campaignId, profileId, advertiserName, parentCallback, edit, toggleEditRow, documentId}) {
    const arrPlacements = []
    const arrObjectPlacements = []


    for (const placement in placements) {
      arrPlacements.push(placement)
      arrObjectPlacements.push(placements[placement])
    }

    const condition = true
    const cookies = new Cookies()
    if (condition) {
        return (
            <>
                <tr key={name + "trid"} className='CBConteiner__tablePlacement-body-row'>
                    <td className='CBConteiner__tablePlacement-body-row-td nohover'></td>
                    <td className='CBConteiner__tablePlacement-body-row-td' title={name}>
                        {name}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td'>
                        {placements[arrPlacements[0]]["PackageId"]}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                        <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                        </td>
                    <td className='CBConteiner__tablePlacement-body-row-td nohover  ' >
                        <form action={`http://beyondmedia.ml:12345/gapi/fsapi/generate-tags/`} target="_blank" rel="noopener noreferrer">
                        <input type="hidden" name="campaignId" value={campaignId}></input>
                        <input type="hidden" name="profileId" value={profileId}></input>
                        <input type="hidden" name="siteName" value={placements[arrPlacements[0]]["SiteName"]}></input>
                        <input type="hidden" name="id_token" value={cookies.get("id_token")}></input>
                        <input type="hidden" name="packageName" value={placements[arrPlacements[0]]["PackName"]}></input>
                        <input type="hidden" name="packageIds" value={placements[arrPlacements[0]]["PackageId"]}></input>
                        <input type="hidden" name="advertiserName" value={advertiserName}></input>
                        </form>
                    </td>
                    
                </tr>
                {arrPlacements.map((placement) => {
                    return (
                        <Placement toggleEditRow={toggleEditRow} name={placement} data={placements[placement]} parentCallback={parentCallback} edit={edit} key={placement}/>
                    )
                })}
            </>
        
            )
        
    }
    else return null
}
export default Package