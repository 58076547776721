import React, { useContext,useState,useEffect } from 'react'
import AuthenticationContext from '../../Contexts/AuthConttext'
import IMAGES from '../../Assets/Img'
import CustomImg from '../../Components/Common/Imgs/customImg'
import "./index.scss"
import { db } from '../../Firebase';
import { getDoc, where, doc } from "firebase/firestore";
import Cookies from 'universal-cookie';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CalendarWidget from './CalendarWidget'

const Home = () => {

    const {email,usuario, scopes, setScopes,isloeaded, setLoadedNav,setAuthenticated, set} = useContext(AuthenticationContext)



    
    // async function getUserFs() {
    //     if (scopes.length === 0 || scopes.length >0) {
    //         const docRef = doc(db, "Users", email)
    //         const docSnap = await getDoc(docRef);
    //         const getData = docSnap.data()
    //         setScopes(getData.scopes)
            
    //     }
    // }

    // async function getRolesFs() {
    //     if (roles) {
    //         const docRef = doc(db, "Scopes", roles)
    //         const docSnap = await getDoc(docRef);
    //         const getData = docSnap.data()
    //         setAviableRoles([...getData.scopes])
    //         console.log(...getData.scopes)
    //     }

        
    // }
    




    const cookies = new Cookies();

    const idToken = cookies.get("id_token", "idToken")
    return (
        <div className='home'>
            <CustomImg {...IMAGES.isologolo} clase="home__logo"  draggable="false"/>
            <p className="home__welcome">{usuario}</p>
            {/* <CalendarWidget /> */}
        </div>
    )
}

export default Home
