import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { firebaseConfig } from "./Firebase";
import { FirebaseAppProvider } from "reactfire";
import { ModalProvider } from "./Contexts/ModalContext";
import { AuthenticationProvider } from "./Contexts/AuthConttext";
import Routing from "./Config/Routing";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import useAuthListener from "./AuthListener.js";
import Login from "./Pages/Login";
import ContextUpdater from "./Contexts/AuthConttext/ContextUpdater.js";
import { NotificationsProvider } from "@mantine/notifications";
import { MantineProvider } from "@mantine/core";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Loader } from "@mantine/core";
import Creatividades from "./Pages/Creatividades";
import Chatbot from "./Components/Globals/Chatbot";

function App() {
  // console.log('Los saluda Sai, Lucho y Gonza, Fran Robredo no ayudo en nada ni lo va a hacer.')
  // lucho pelotudo

  const { loggedIn, checkingStatus } = useAuthListener();
  // console.log("isLoggedIn", loggedIn)
  return (
    <AuthenticationProvider>
      <MantineProvider>
        <NotificationsProvider>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <ModalProvider>
              <div className="App">
                {checkingStatus ? (
                  <>
                    <div className="loaderRefresh">
                      <Loader color="orange" size={`xl`} />
                    </div>
                  </>
                ) : loggedIn ? (
                  // if user is logged in, grant the access to the components
                  <>
                    <ContextUpdater />
                    <Chatbot />
                    <Routing />
                  </>
                ) : (
                  // else render login page
                  <Router>
                    <Routes>
                      <Route
                        exact
                        path="/creatividades"
                        element={<Creatividades />}
                      />
                      <Route path="*" element={<Login />} />
                    </Routes>
                  </Router>
                )}
              </div>
            </ModalProvider>
          </FirebaseAppProvider>
        </NotificationsProvider>
      </MantineProvider>
    </AuthenticationProvider>
  );
}

export default App;
