import React from 'react'

const Error404 = () => {
    return (
        <div>
            <p>Error404</p>
        </div>
    )
}

export default Error404
