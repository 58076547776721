import React, { useState, useContext } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './newNavbar.scss'
import IMAGES from '../../../Assets/Img';
import CustomImg from '../../Common/Imgs/customImg';
import Routes from './routes';
import { Link } from 'react-router-dom';
import './newNavbar.scss'
import AuthenticationContext from '../../../Contexts/AuthConttext';


const NewNavbar = () => {
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const { scopes, loadedNav } = useContext(AuthenticationContext)
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
    setToggled(!toggled)
  };
  return (
    <>
      <ProSidebar
        collapsed={collapsed}
        toggled={false}
        breakPoint="xs"
        onToggle={handleCollapsedChange}
        width='270px'
        collapsedWidth='65px'
        onMouseLeave={handleCollapsedChange}
        onMouseEnter={handleCollapsedChange}
      >
        <SidebarHeader>
          {collapsed ? <CustomImg {...IMAGES.isologolo} clase={"navBar__header-logoCol"} />: <CustomImg {...IMAGES.isologotipo} clase={"navBar__header-logo"} />}
          
        </SidebarHeader>
        <SidebarContent>
          <ProSidebar>
            <Menu iconShape="round"
              innerSubMenuArrows='true'
            >{ loadedNav ?
              Routes.map(data =>
                data.subMenu.length === 0 ?
                  (data.tools.some(r=> scopes.includes(r)) ? <MenuItem icon={data.icon} >{data.name} <Link to={data.module} /></MenuItem> : null) :
                  (data.tools.some(r=> scopes.includes(r)) ? <SubMenu title={data.name} icon={data.icon}>
                    {data.subMenu.map(data =>
                      data.subMenu.length === 0 ?
                        (data.tools.some(r=> scopes.includes(r)) ? <MenuItem icon={data.icon} >{data.name} <Link to={data.module} /></MenuItem> : null) :
                        (data.tools.some(r=> scopes.includes(r)) ? <SubMenu title={data.name} icon={data.icon}>
                          {data.subMenu.map(data =>
                            (data.tools.some(r=> scopes.includes(r)) ? <MenuItem icon={data.icon} >{data.name} <Link to={data.module} /></MenuItem> : null)
                          )}
                        </SubMenu> : null)
                    )
                    }
                  </SubMenu> : null)
              )
              
              :null


            }
             
            </Menu>
          </ProSidebar>
        </SidebarContent>

      </ProSidebar>
    </>
  );
}


export default NewNavbar

