import React, { useState, useEffect, useContext, useCallback } from 'react';
import './index.scss'
import { db } from '../../../Firebase';
import { onSnapshot, collection, doc, query, orderBy, limit, startAfter, where, updateDoc } from "firebase/firestore";
import AuthenticationContext from '../../../Contexts/AuthConttext';
import { useNotifications } from '@mantine/notifications';
import ModalText from './ModalText';
import ModalCargarComp from '../../../Components/Common/ModalCargaComp';
import Posts from './Posts';

import { Loader } from '@mantine/core';
const VerFacturas = () => {
    const [aviableBills, setAviableBills] = useState([])
    const [currentPost, setCurrentPost] = useState([]);
    const [dateSearchInit, setDateSearchInit] = useState("")
    const [dateSearchEnd, setDateSearchEnd] = useState("")
    const [numberSearch, setNumberSearch] = useState('');
    const [dateSearch, setDateSearch] = useState("all");
    const [proovSearch, setProovSearch] = useState("all")
    const [letterSearch, setLetterSearch] = useState("all")
    const [showWriteModal, setShowWriteModal] = useState(false);
    const [cargaModal, setCargaModal] = useState(false);
    const [id, setId] = useState('');
    const [items, setItems] = useState('');
    const [name, setName] = useState('');
    const [isLoaded, setIsLoaded] = useState(false)
    const { scopes, email, usuario } = useContext(AuthenticationContext)
    const dondeLePego = email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
    const notifications = useNotifications();
    const [billsToPay, setBillsToPay] = useState([]);
    const [idBillsToPay, setIdBillsToPay] = useState([]);
    //FUNCTIONS

    const addBillsToPay = (x, y) => {
        if (billsToPay.length === 0) {
            setBillsToPay([y])
            setIdBillsToPay([x])
        } else {
            if (billsToPay.find(i => (i == y))) {
                setBillsToPay(billsToPay.filter((element) => element != y))
                setIdBillsToPay(idBillsToPay.filter((element) => element != x))
            } else {
                setBillsToPay([...billsToPay, y])
                setIdBillsToPay([...idBillsToPay, x])
            }
        }
    }
    const limpiarFc = () => {
        setBillsToPay([])
        setIdBillsToPay([])
    }



    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) == e.target.clientHeight;


        if (bottom) {
            goNext()

                ;
        }
    }

    const openCommentModal = (id, item) => {
        showWriteModal ? setShowWriteModal(false) : setShowWriteModal(true)

        setId(id)
        setItems(item)
    }

    const openCompModal = (id, name) => {
        cargaModal ? setCargaModal(false) : setCargaModal(true)

        setId(id)
        setName(name)
    }

    const closeCompModal = () => cargaModal ? setCargaModal(false) : setCargaModal(true)

    const closeCommentModal = () => showWriteModal ? setShowWriteModal(false) : setShowWriteModal(true)


    const updateAuth = async (id, status, name) => {
        if (dondeLePego === "BEYOND") {
            const fcRef = doc(db, "Facturas", id);
            if (status) {
                await updateDoc(fcRef, {
                    autorizado: false,
                    autorizo: ''
                });
                setBillsToPay(billsToPay.filter((element) => element != name))
                setIdBillsToPay(idBillsToPay.filter((element) => element != id))
            } else {
                await updateDoc(fcRef, {
                    autorizado: true,
                    autorizo: usuario
                });
                notifications.showNotification({
                    title: 'Factura Autorizada!!',
                    message: `La factura ${id} ha sido autorizada para su pago`,
                    color: 'green',
                    autoClose: 5000,
                })

            }
        } else {
            const fcRef = doc(db, "FacturasDigital", id);
            if (status) {
                await updateDoc(fcRef, {
                    autorizado: false,
                    autorizo: ''
                });

            } else {
                await updateDoc(fcRef, {
                    autorizado: true,
                    autorizo: usuario
                });
                notifications.showNotification({
                    title: 'Factura Autorizada!!',
                    message: `La factura ${id} ha sido autorizada para su pago`,
                    color: 'green',
                    autoClose: 5000,
                })
            }
        }


    }



    const getFromFs = async () => {

        if (letterSearch === 'all' && proovSearch === 'all' && dateSearch === 'all' && numberSearch === '') {
            if (dondeLePego === 'BEYOND') {

                const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc')
                )

                const unsubscribe = onSnapshot(q, (querySnapshot) => {

                    const newBillsDataArray = querySnapshot.docs
                        .map((doc) => ({ ...doc.data(), id: doc.id }));
                    newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                    changeDate(newBillsDataArray)
                    setAviableBills(newBillsDataArray);
                    setIsLoaded(true)
                    setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                });
            } else {
                const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc')
                )

                const unsubscribe = onSnapshot(q, (querySnapshot) => {

                    const newBillsDataArray = querySnapshot.docs
                        .map((doc) => ({ ...doc.data(), id: doc.id }));
                    newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                    changeDate(newBillsDataArray)
                    setAviableBills(newBillsDataArray);
                    setIsLoaded(true)
                    setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])

                })
                    .catch((err) => {
                        console.error("Failed to retrieve data", err);
                    });

            }
        } else {
            if (dondeLePego === 'BEYOND') {
                if (!numberSearch) {
                    if (letterSearch !== 'all' && proovSearch === 'all' && dateSearch === 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('pago', 'desc'), where("items.tipo_fac", "==", letterSearch)
                        )

                        const unsubscribe = onSnapshot(q, (querySnapshot) => {

                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    } else if (letterSearch === 'all' && proovSearch !== 'all' && dateSearch === 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.rs", "==", proovSearch)
                        )
                        const unsubscribe = onSnapshot(q, (querySnapshot) => {

                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    } else if (letterSearch !== 'all' && proovSearch !== 'all' && dateSearch === 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.rs", "==", proovSearch), where("items.tipo_fac", "==", letterSearch)
                        )

                        const unsubscribe = onSnapshot(q, (querySnapshot) => {

                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    } else if (letterSearch === 'all' && proovSearch === 'all' && dateSearch !== 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000))
                        const unsubscribe = onSnapshot(q, (querySnapshot) => {
                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    } else if (letterSearch === 'all' && proovSearch !== 'all' && dateSearch !== 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000), where("items.rs", "==", proovSearch))
                        const unsubscribe = onSnapshot(q, (querySnapshot) => {
                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    } else if (letterSearch !== 'all' && proovSearch === 'all' && dateSearch !== 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000), where("items.tipo_fac", "==", letterSearch))
                        const unsubscribe = onSnapshot(q, (querySnapshot) => {
                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    } else if (letterSearch !== 'all' && proovSearch !== 'all' && dateSearch !== 'all') {
                        const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000), where("items.rs", "==", proovSearch), where("items.tipo_fac", "==", letterSearch))
                        const unsubscribe = onSnapshot(q, (querySnapshot) => {
                            const newBillsDataArray = querySnapshot.docs
                                .map((doc) => ({ ...doc.data(), id: doc.id }));
                            newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                            changeDate(newBillsDataArray)
                            setAviableBills(newBillsDataArray);
                            setIsLoaded(true)
                            setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                        });
                    }
                } else {
                    const q = query(collection(db, "Facturas"), where("items.nro_fac", "==", numberSearch))
                    console.log(numberSearch.toString());
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => {
                                return ({ ...doc.data(), id: doc.id })
                            });
                        // newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                        console.log(newBillsDataArray);
                        changeDate(newBillsDataArray)
                        setAviableBills(newBillsDataArray);
                        setIsLoaded(true)
                    });
                }



            } else {
                if (letterSearch !== 'all' && proovSearch === 'all') {

                    const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc'), where("items.tipo_fac", "in", ['Credito', 'CREDITO'])
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills(newBillsDataArray);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch === 'all' && proovSearch !== 'all') {
                    const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc'), where("items.rs", "==", proovSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills(newBillsDataArray);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch !== 'all' && proovSearch !== 'all' && dateSearch === 'all') {
                    const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc'), where("items.rs", "==", proovSearch), where("items.tipo_fac", "==", letterSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills(newBillsDataArray);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                }
            }
        }



    }

    const goNext = () => {
        if (letterSearch === 'all' && proovSearch === 'all' && dateSearch === 'all') {
            if (dondeLePego === 'BEYOND') {
                const q = query(collection(db, "Facturas"), limit(10), orderBy('items.fecha', 'desc'), startAfter(currentPost))
                const unsubscribe = onSnapshot(q, (querySnapshot) => {

                    const newBillsDataArray = querySnapshot.docs
                        .map((doc) => ({ ...doc.data(), id: doc.id }));
                    newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                    changeDate(newBillsDataArray)
                    setAviableBills([...aviableBills, ...newBillsDataArray]);
                    setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    setIsLoaded(true)
                });
            } else {
                const q = query(collection(db, "FacturasDigital"), limit(10), orderBy('pago', 'desc'), startAfter(currentPost))
                const unsubscribe = onSnapshot(q, (querySnapshot) => {

                    const newBillsDataArray = querySnapshot.docs
                        .map((doc) => ({ ...doc.data(), id: doc.id }));
                    newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                    changeDate(newBillsDataArray)
                    setAviableBills([...aviableBills, ...newBillsDataArray]);
                    setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    setIsLoaded(true)
                });
            }
        } else {

            if (dondeLePego === 'BEYOND') {
                if (letterSearch !== 'all' && proovSearch === 'all' && dateSearch === 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('pago', 'desc'), startAfter(currentPost), where("items.tipo_fac", "==", letterSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);;
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch === 'all' && proovSearch !== 'all' && dateSearch === 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('pago', 'desc'), startAfter(currentPost), where("items.rs", "==", proovSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);;
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch !== 'all' && proovSearch !== 'all' && dateSearch === 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('pago', 'desc'), startAfter(currentPost), where("items.rs", "==", proovSearch), where("items.tipo_fac", "==", letterSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch === 'all' && proovSearch === 'all' && dateSearch !== 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000))
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch === 'all' && proovSearch !== 'all' && dateSearch !== 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), startAfter(currentPost), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000), where("items.rs", "==", proovSearch))
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch !== 'all' && proovSearch === 'all' && dateSearch !== 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), startAfter(currentPost), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000), where("items.tipo_fac", "==", letterSearch))
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch !== 'all' && proovSearch !== 'all' && dateSearch !== 'all') {
                    const q = query(collection(db, "Facturas"), limit(30), orderBy('items.fecha', 'desc'), startAfter(currentPost), where("items.fecha", ">=", new Date(dateSearchInit).getTime() / 1000), where("items.fecha", "<=", new Date(dateSearchEnd).getTime() / 1000), where("items.rs", "==", proovSearch), where("items.tipo_fac", "==", letterSearch))
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)
                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                }

            } else {
                if (letterSearch !== 'all' && proovSearch === 'all') {
                    const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc'), startAfter(currentPost), where("items.tipo_fac", "==", letterSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);;
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch === 'all' && proovSearch !== 'all') {
                    const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc'), startAfter(currentPost), where("items.rs", "==", proovSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);;
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                } else if (letterSearch !== 'all' && proovSearch !== 'all') {
                    const q = query(collection(db, "FacturasDigital"), limit(30), orderBy('pago', 'desc'), startAfter(currentPost), where("items.rs", "==", proovSearch), where("items.tipo_fac", "==", letterSearch)
                    )

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {

                        const newBillsDataArray = querySnapshot.docs
                            .map((doc) => ({ ...doc.data(), id: doc.id }));
                        newBillsDataArray.splice(newBillsDataArray.findIndex(element => element.id == "current"), 1)

                        changeDate(newBillsDataArray)
                        setAviableBills([...aviableBills, ...newBillsDataArray]);
                        setIsLoaded(true)
                        setCurrentPost(querySnapshot.docs[querySnapshot.docs.length - 1])
                    });
                }

            }

        }

    }






    const changeDate = (arr) => {
        for (const x of arr) {
            if (typeof (x.items.fecha) === 'string') {
                if (x.items.fecha.includes('.')) {
                    x.items.fecha = new Date(x.items.fecha.split('.').reverse().join('/'))

                } else if (x.items.fecha.includes('/')) {
                    x.items.fecha = new Date(x.items.fecha.split('/').reverse().join('/'))

                } else if ((x.items.fecha).includes('-')) {
                    x.items.fecha = new Date(x.items.fecha.split('-').reverse().join('/'))

                }
            } else {
                x.items.fecha = new Date(x.items.fecha * 1000)

            }






        }
    }





    const handleSearchLetter = (event) => {
        updateLetterSearch(event.target.value)

    };
    const updateLetterSearch = (e) => setLetterSearch(e)

    const handleSearchProov = (event) => {
        updateProovSearch(event.target.value);


    }
    const updateProovSearch = (e) => setProovSearch(e)

    const handleSearchDateInit = (event) => {
        event.target.value.length === 0 ? setDateSearchInit("") : setDateSearchInit((event.target.value));
    }
    const handleSearchDateEnd = (event) => {
        event.target.value.length === 0 ? setDateSearchEnd("") : setDateSearchEnd((event.target.value));
    }

    const handleSearchNumber = (event) => {
        updateNumberSearch(event.target.value.toString());


    }
    const updateNumberSearch = (e) => setNumberSearch(e)


    const limpiarFiltros = () => {
        setLetterSearch('all')
        setProovSearch('all')
        setDateSearch('all')
        setDateSearchInit('')
        setDateSearchEnd('')
        setNumberSearch('')

    }


    //USEEFFECTS

    useEffect(() => {
        (letterSearch === 'all' && proovSearch === 'all' && dateSearch === 'all' && numberSearch === '') && getFromFs()
    }, [letterSearch, proovSearch, dateSearch, numberSearch]);

    useEffect(() => {
        dateSearchInit !== '' && dateSearchEnd !== '' ? setDateSearch('yes') : setDateSearch('all')
        console.log(dateSearchInit, dateSearchEnd);
    }, [dateSearchInit, dateSearchEnd]);



    useEffect(() => {
        getFromFs()
    }, [])





    if (scopes && scopes.find(element => element === 'verFacturas')) {
        return (<>
            <div className='verFacturasConteiner' onScroll={(e) => handleScroll(e)}>
                {/* <button onClick={newDate}>asds</button> */}
                <div className='verFacturasConteiner__listaFacturas'>
                    <table className='verFacturasConteiner__listaFacturas-table'>
                        <thead className='verFacturasConteiner__listaFacturas-table-thead'>
                            <tr className='verFacturasConteiner__listaFacturas-table-thead-row'>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' colspan="18">
                                    <div className="verFacturasConteiner__listaFacturas-table-thead-row-th-filtersReporting">
                                        <div className="verFacturasConteiner__listaFacturas-table-thead-row-th-filtersReporting-div">
                                            <div className='inputDiv'>
                                                <label className='inputDiv-label'>
                                                    N° Factura:
                                                    <input type="text" value={numberSearch} onChange={handleSearchNumber} />
                                                </label>
                                                <label className='inputDiv-label'>
                                                    Desde:
                                                    <input type="date" value={dateSearchInit} onChange={handleSearchDateInit} />
                                                </label>
                                                <label className='inputDiv-label'>
                                                    Hasta:
                                                    <input type="date" name='1' value={dateSearchEnd} onChange={handleSearchDateEnd} />
                                                </label>
                                                <label className='inputDiv-label'>
                                                    <select value={letterSearch} onChange={handleSearchLetter}>
                                                        <option value="all">Todas las Facturas</option>
                                                        <option value="A">A</option>
                                                        <option value="C">C</option>
                                                        <option value="E">E</option>
                                                        <option value="M">M</option>
                                                        <option value="CREDITO">Nota de Credito</option>
                                                        <option value="DEBITO">Nota de Debito</option>
                                                    </select>
                                                </label>
                                                <label className='inputDiv-label'>
                                                    <select value={proovSearch} onChange={handleSearchProov}>
                                                        <option value="all">Todos los Proveedores</option>
                                                        <option value="GOOGLE">Google</option>
                                                        <option value="FACEBOOK">Facebook</option>
                                                        <option value="INFOBAE">Infobae</option>
                                                        <option value="IPROFESIONAL">iProfesional</option>
                                                        <option value="LA NACION">La Nación</option>
                                                        <option value="IMS">IMS</option>
                                                    </select>
                                                </label>
                                                {/* 
                                                <label className='inputDiv-label'>
                                                    <input className='inputDiv-input' icon='search' placeholder='Buscar por Nro de factura:' type="text" onInput={(e) => handleSearchNumber(e.target.value)} />
                                                </label> */}
                                                <button className='buttonStandard smallB' onClick={getFromFs}>Buscar</button>
                                                <button className='buttonStandard smallB' onClick={limpiarFiltros} >Limpiar Filtros</button>
                                                <a href="https://docs.google.com/spreadsheets/d/1LuEyQb2vsT1ZJOMONmZ_CngY1-Wx9tAv4FWRjcCS5r0/edit#gid=0" target={`_blank`}><button className='buttonStandard smallB' >Sheets</button></a>

                                                <button className='buttonStandard' onClick={() => limpiarFc()} >Limpiar Seleccionados</button>
                                                {(scopes && scopes.find(element => element === 'cargarPagos')) && billsToPay.length > 0 && <button className='buttonStandard smallB' onClick={() => setCargaModal(!cargaModal)} >Cargar Pagos</button>}
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr className='rowGris'>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Tipo de Factura</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th'>Proveedor</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th'>PV</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th'>NroFactura</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Fecha</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Subtotal</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >IVA</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Percep IVA</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >IIBBcaba</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >IIBBgba</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Total</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Descripcion</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Comentario</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Aprobar</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Estado</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Abonar</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Descargar</th>
                                <th className='verFacturasConteiner__listaFacturas-table-thead-row-th' >Descargar Comp</th>
                            </tr>
                        </thead>
                        <tbody className={isLoaded ? 'verFacturasConteiner__listaFacturas-table-body' : 'verFacturasConteiner__listaFacturas-table-body tBodyCenter'}>
                            {!isLoaded ?
                                <>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                    </tr>

                                </>
                                :

                                <Posts posts={aviableBills} loading={isLoaded} openCommentModal={openCommentModal} openCompModal={openCompModal} fn={addBillsToPay} arr={billsToPay} fna={updateAuth} />
                            }
                        </tbody>
                    </table>


                </div>


                {showWriteModal ? <ModalText handleDisplay={closeCommentModal} id={id} item={items} agency={dondeLePego} /> : null}

            </div>
            {cargaModal ? <ModalCargarComp handleDisplayC={closeCompModal} id={idBillsToPay} name={billsToPay} fn={setBillsToPay} fn2={setIdBillsToPay} /> : null}
        </>
        )




    } else {
        return (
            <Loader color="orange" size={`xl`} />
        )
    }

}

export default VerFacturas