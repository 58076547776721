import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc , setDoc} from "firebase/firestore";
import { db } from '../../../Firebase';
import { useNotifications } from '@mantine/notifications';

import CustomImg from '../Imgs/customImg';
import IMAGES from '../../../Assets/Img';
import './index.scss'
import { Loader } from '@mantine/core';

const ModalUpdateFc = ({handleShow, id, newCall, operation}) => {
  const [docId, setDocId] = useState(id);
  const [updated, setUpdated] = useState(true);
  let items
  let lastItem
  const notifications = useNotifications();
  useEffect(() => {
    setDocId(id)
  }, []);


  const getDocu = async () =>{
    if (operation === 'comentarioFactura') {
      const docRef = doc(db, "Facturas", docId);
      const docSnap = await getDoc(docRef);
      const getData = docSnap.data();
      items = getData.items
    }
  }
  
  const updateFs =  async () => {
    setUpdated(false)
    if (operation === 'comentarioFactura') {
      const docRef = doc(db, "Facturas", docId);
      await updateDoc(docRef, {
          "items": items
    })
    newCall()
    setUpdated(true)
    console.log(items)
    setTimeout(() => {
      handleShow()
    }, 2000);
  }
    if (operation === 'reportBug'){
      const q = doc(db, "Tickets" , "Contador")
      const docSnap  = await getDoc(q)
      const datuli = docSnap.data()
      lastItem =datuli.counter+1
      const counter = datuli.counter+1
      updateDoc(q, {
         "counter": counter
          
   })
      await setDoc(doc(db, "Tickets", `Ticket-${lastItem.toString().length <2 ? `0${lastItem}` : lastItem}`), {
        description: items
      })

      setUpdated(true)
      notifications.showNotification({
        title: 'Ticket Enviado!!',
        message: `El reporte ha sido enviado`,
        color: 'orange',
        autoClose: 5000,
    })
      setTimeout(() => {

        handleShow()
      }, 1000);
    }
 
  }

  const handleNewComment = (event) => {
    if (operation === 'reportBug') {
      items = event
    }
    
}

useEffect(() => {
  getDocu()
}, []);

useEffect(() => {
  lastItem && console.log(lastItem.length)
}, [lastItem]);

  return (
    <>
      <div className='modalConteiner'>

        <div className='modalDiv'>
        <div className={'modalDiv__close'} onClick={() => handleShow()}>
            <CustomImg {...IMAGES.close}  />
            </div>
          <div className='modalDiv__figcaption'>
          <CustomImg {...IMAGES.adminLogo} clase={'modalDiv__figcaption-img'} />
          </div>
            <div className='textConteiner'>
          <textarea  className='modalDiv__update' type="text" onInput={(e) => handleNewComment(e.target.value)}>
          </textarea>
            </div>
            {updated ? <button className={`buttonStandard`} onClick={updateFs}>Subir Ticket</button> : <Loader color="orange" size={`sm`} />}
        </div>
      </div>
    </>
  )


};

export default ModalUpdateFc
