import React,{useEffect,useContext, useState} from 'react';
import AuthenticationContext from '../../../Contexts/AuthConttext';
import ModalContext from '../../../Contexts/ModalContext';
import Cookies from 'universal-cookie';

const Posts = ({ posts, loading }) => {
  const { email } = useContext(AuthenticationContext)
  const cookies = new Cookies();
  const [showWriteModal, setShowWriteModal] = useState(false);
  const [id, setId] = useState('');
  const [items, setItems] = useState('');
  const openCommentModal = (id, item) => {
      showWriteModal ? setShowWriteModal(false) : setShowWriteModal(true)

      setId(id)
      setItems(item)
  }
  const truncate = (input) => input.length > 10 ? `${input.substring(0, 10)}...` : input;
  const copyToClipBoard = (e) => {
    navigator.clipboard.writeText(e)
    alert("Copied!");
}

  const { setShowModal, setText } = useContext(ModalContext)
  const openModal = (texto) => {
      setText(texto)
          ; setShowModal(true)
  }
    if (!loading) {
      return <h2>Loading...</h2>;
    }else{
      return(<>
          {posts.map((data) => (
                                <tr className='reporting__table-body-row'>
                                    <td className='reporting__table-body-row-td pointer' title={data.campaignName} onClick={() => copyToClipBoard(`${data.campaignName}`)}>{truncate(data.campaignName)}</td>
                                    <td className='reporting__table-body-row-td pointer' title={data.siteName} onClick={() => copyToClipBoard(`${data.siteName}`)}>{truncate(data.siteName)}</td>
                                    <td className='reporting__table-body-row-td pointer' title={data.placementName}>{truncate(data.placementName)}</td>
                                    <td className='reporting__table-body-row-td' title={data.placementId} onClick={() => copyToClipBoard(`${data.placementId}`)}> {data.placementId}</td>
                                    <td className='reporting__table-body-row-td' title={data.date}> {data.date}</td>
                                    <td className='reporting__table-body-row-td' title={data.type}>{data.type}</td>
                                    <td className='reporting__table-body-row-td' title={data.country}> {data.country}</td>
                                    <td className='reporting__table-body-row-td' title={data.impressions}> {data.impressions}</td>
                                    <td className='reporting__table-body-row-td' title={data.clicks}> {data.clicks}</td>
                                    <td className='reporting__table-body-row-td' title={data.ctr}>{Math.round(data.ctr)}</td>
                                </tr>
                            )
                                      )}
      </>)


    }

  
};

export default Posts;