import React from 'react';
function Log({documentId, data}){
    // console.log(data)
    return (
        <div id="logger" style={{height: "10rem", overflow: "auto"}} className='CBConteiner__tablePlacementLog'>
    
            {data.map(function(each) {
                var time = new Date((each.start) * 1000)
                time = time.toLocaleString('en-us')
                return (
                <div style={{border: "1px solid black", whiteSpace: "pre-line"}} key={time}>
                    <p>{each.uploaded_by} at {time} - run {each.number}</p>
                    <p>{each.total_uploaded} placement(s) in {each.time}s, at a rate of {each.ratio} sec/plcmnt.</p>
                    {each.exceptions} errors.
                </div>
                )
            })}

        </div>
    )
}
export default Log