import React, { useState, useEffect,useContext } from 'react';
import Cookies from 'universal-cookie';
import CustomImg from '../../../../Components/Common/Imgs/customImg';
import IMAGES from '../../../../Assets/Img';
import { useNotifications } from '@mantine/notifications';
import AuthenticationContext from '../../../../Contexts/AuthConttext';
import './index.scss'
import { X } from 'tabler-icons-react';
const ModalText = ({ handleDisplay, id, item,agency }) => {
  const [editMode, setEditMode] = useState(false)
  const [newComment, setNewComment] = useState('');
  const [updated, setUpdated] = useState(false);
  const notifications = useNotifications();
  const { email,scopes } = useContext(AuthenticationContext)
  const dondeLePego  =email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
  const galletitas = new Cookies()
  const idToken = galletitas.get("id_token")

  //FUNCTIONS
  const handleEditMode = () => editMode ? setEditMode(false) : setEditMode(true)


  const updateFs = async () => {
    const target_1 = `https://adm.beyondmediaportal.com/facturacion/comentario_sheets?agency=${dondeLePego}&doc_id=${id}&comentario=${newComment}&id_token=${idToken}`;
    console.log(target_1);
 
    setUpdated(false)

    fetch(target_1, {
        method: "POST",
        mode: "cors",
        headers: {
            "idToken": idToken,
            "agency": dondeLePego
        },
    }).then(response => {
      response.json()
      if (response.status === 200) {
          notifications.showNotification({
              title: 'Comentario cargado y enviado!!',
              message: `El comentario de la factura fue correctamente cargado! `,
              color: 'green',
              autoClose: 5000,
          })
          setUpdated(true)
          handleEditMode()
      } else {
          notifications.showNotification({
              title: 'Error en la carga',
              message:`El comentario de la factura no fue cargado! `,
              color: 'red',
              autoClose: 5000,
          })
         
          setUpdated(true)
          handleEditMode()
      }
  })

  }

  const handleNewComment = (e) => setNewComment(e)

useEffect(() => {
console.log(newComment);
}, [newComment]);

  if (!editMode) {
    return (<>
      <div className='modalConteiner'>

        <div className='modalDiv'>
          <div className={'modalDiv__close'} onClick={() => handleDisplay()}>
            <X size={50} strokeWidth={3} color={'#fafafa'}/>
          </div>
          <div className='modalDiv__figcaption'>
            <CustomImg {...IMAGES.comentario} clase={'modalDiv__figcaption-img'} />
          </div>
          <div className='textConteiner'>
            <p className='modalDiv__text'>
              {item}
            </p>

          </div>

          <button className='buttonStandard' onClick={() => handleEditMode()}>editar</button>
        </div>
      </div>
    </>
    )
  } else {
    return (
      <div className='modalConteiner'>
        <div className='modalDiv'>
          <div className={'modalDiv__close'} onClick={() => handleDisplay()}>
            <X size={50} strokeWidth={3} color={'#fafafa'}/>
          </div>
          <div className='modalDiv__figcaption'>
            <CustomImg {...IMAGES.adminLogo} clase={'modalDiv__figcaption-img'} />
          </div>
          <div className='textConteiner'>
            <textarea className='modalDiv__update' type="text" onInput={(e) => handleNewComment(e.target.value)}>
            </textarea>
          </div>
          <button className='buttonStandard' onClick={() => handleEditMode()}>Ver Comentario</button>
          <button className={`buttonStandard ${updated && "success"}`} onClick={updateFs}>{!updated ? 'Subir Cambios' : 'Actualizado!'}</button>
        </div>
      </div>)

  }




};

export default ModalText
