import React, { useState, useContext, useRef, useEffect } from "react";

const Chatbot = () => {
  const [hidden, setHidden] = useState(true);
  const local = false;
  const commonStyles = {
    zIndex: "10",
    position: "absolute",
    bottom: "10px",
    right: "10px",
    borderRadius: "10px",
    backgroundColor: "#e5e5e5",
  };
  const hiddenDiv = (
    <div
      style={{
        ...commonStyles,
        borderRadius: "30px",
        height: "35px",
        width: "200px",
        cursor: "pointer",
        backgroundColor: "#ea6023",
        display: "flex",
      }}
      onClick={() => setHidden(false)}
    >
      <p style={{ height: "fit-content", margin: "auto" }}>Chatbot by Beyond</p>
    </div>
  );
  return hidden ? (
    hiddenDiv
  ) : (
    <div
      style={{
        ...commonStyles,
        height: "50%",
        width: "400px",
        transform: "translateY(0px)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "5px",
          marginRight: "2px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => setHidden(true)}
      >
        x
      </div>
      <iframe
        style={{ height: "100%", width: "100%", borderRadius: "10px" }}
        src={
          local
            ? "http://localhost:3001"
            : "https://ai-bots-showcase.firebaseapp.com/"
        }
        allow="microphone"
      ></iframe>
    </div>
  );
};

export default Chatbot;
