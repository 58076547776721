import React from "react"

function copyclick(e, value) {
    navigator.clipboard.writeText(value);
    console.log(value)
}


function Placement(props) {
    var data = props.data
    // var editmode = props.edit
    var cond = false
    var tdclass = cond ? "noteditable" : "editable"
    function handleToggleEditRow() {
        props.toggleEditRow(data.docId)
    }
    function handleParentCallback(e) {
        props.parentCallback(data.docId)
    }
    function handleSubmit(e) {
        e.preventDefault()
        props.updatePlacements(data)
    }

    const truncate = (input) => input.length > 10 ? `${input.substring(0, 10)}...` : input;
    // console.log(data)
    if (data.Status !== "hidden") {
        if (true) {
            return (
                <>
                                <tr key={data["Url"] + "-tr"} className='CBConteiner__tablePlacement-body-row' id={data.docId} >
                    <td className='CBConteiner__tablePlacement-body-row-td nohover' >

                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td nohover'>

                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td nohover'>
                        
                    </td>
                    
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["PLName"]}>
                        {truncate(data["PLName"])} 
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["Type"]}>
                        {truncate(data["Type"])}
                    </td>
        
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["Size"]} style={{padding: 0}}>
                        {data["Size"]}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["StartDate"]}>
                        {data["StartDate"]}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["EndDate"]}>
                        {data["EndDate"]}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["Url"]}>
                        {truncate(data["Url"])}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td' className="align-center" title={data["Drive"]}>
                        {truncate(data["Drive"])}
                    </td>
                    <td className='CBConteiner__tablePlacement-body-row-td' title={data["PlacementId"]}>
                        {data["PlacementId"]}
                    </td>          
                    <td className='CBConteiner__tablePlacement-body-row-td' className="align-center">
                        {data["Status"]}
                    </td>        
                    <td className='CBConteiner__tablePlacement-body-row-td'>
                        <input style={{alignContent: "center"}} type="checkbox" onChange={handleParentCallback} className="inputPlacement"></input>
                    </td>  
                </tr>

                </>
            )
    
        }
               
    }
    else return null
}
export default Placement