import React,{useState
} from 'react'

const Modal = ({handlemodal,fn}) => {
    const [localData, setLocalData] = useState('');
    const updateFather = () =>{
        fn(localData)
        setTimeout(() => {
            handlemodal()
        }, 50);
    }
  return (
    <div className='modalConteiner'>
            <div className='divModal'> 
    <div className='divButton'>
    <textarea className='textarea'  onInput={(e) => setLocalData(e.target.value)}></textarea>
    </div>
    <p>Para visualizar copie la etiqueta IFRAME correspondiente.</p>
    <div className='divButtons'>
    <button onClick={updateFather} className="buttonStandard">Testear</button>
    <button onClick={handlemodal} className="buttonStandard">Cerrar</button>
    </div>
    </div>


    </div>
  )
}

export default Modal