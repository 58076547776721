import React, { useState, useEffect, useContext } from 'react'
import "./index.scss"
import Cookies from 'universal-cookie';
import { db } from '../../Firebase';

import { getDoc, doc, onSnapshot } from "firebase/firestore";
import Modules from '../../Components/Globals/NavBar/routes';
import Posts from './Posts';
import Numberpages from '../../Components/Common/Numberpages';
import AuthenticationContext from '../../Contexts/AuthConttext';
import { Loader } from '@mantine/core';

const CMReporting = () => {
    const [data, setData] = useState([])
    const [aviableAcc, setAviableAcc] = useState([])
    const [postsPerPage] = useState(10);
    const [cuenta, setCuenta] = useState(undefined)
    const [isloaded, setIsloaded] = useState(false)
    const [dataSorted, setDataSorted] = useState(true)
    const [ctrSorted, setCtrSorted] = useState(true)
    const [impresionSorted, setImpresionSorted] = useState(true)
    const [placementId, setPlacementId] = useState(true)
    const [countrySorted, setCountrySorted] = useState(true)
    const [dateSorted, setDateSorted] = useState(true)
    const [typeSorted, setTypeSorted] = useState(true)
    const [placementNameSorted, setPlacementNameSorted] = useState(true)
    const [siteNameSorted, setSiteNameSorted] = useState(true)
    const [display, setDisplay] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const { email, scopes } = useContext(AuthenticationContext)
    const permisos = Modules[1].subMenu[0].subMenu[1].roles
    const dondeLePego  =email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
    const cookies = new Cookies();



    const idToken = cookies.get("id_token", "idToken", { path: '/' })
    const apiLink = `https://api.beyondmediaportal.com/gapi/run_report?id_token=${idToken}&user=${email}&account=${cuenta}&agency=${dondeLePego}`


    //FUNCTIONS
    async function fetchReport() {
        const xst = await setIsloaded(false)
        const response = await fetch(apiLink, { mode: 'no-cors' });
        const datita = await getdatafromfs()
        const xstf = await setIsloaded(true)
    }


    async function getdatafromfs() {
        const q = doc(db, "Reporting", email)
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (doc) => {
            if (doc.exists()) {
                const getData = doc.data();
                if (!cuenta) { setAviableAcc(Object.keys(getData)) }
                if (!cuenta) { setCuenta(Object.keys(getData)[0]) }
                setIsloaded(true)
                if (cuenta) {
                    setData(getData[cuenta].latest)
                }
                const source = doc.metadata.fromCache ? "local cache" : "server";
                console.log("Data came from " + source);
                console.log(doc);
            } else {
                // doc.data() will be undefined in this case
                console.log(doc)
                console.log("No such document!");
            }
        });

    }



    const handleFilter = (event) => {
        setCuenta(event.target.value)
        console.log(event.target.value)
    }


    const sortingClicks = () => {
        if (dataSorted) {
            const sorted = data.sort((a, b) => (a.clicks) - (b.clicks))
            setDataSorted(false)
        } else {
            const sorted = data.sort((b, a) => (a.clicks) - (b.clicks))
            setDataSorted(true)
        }
    }
    const sortingCtr = () => {
        if (ctrSorted) {
            const sorted = data.sort((a, b) => (a.ctr) - (b.ctr))
            setCtrSorted(false)
        } else {
            const sorted = data.sort((b, a) => (a.ctr) - (b.ctr))
            setCtrSorted(true)
        }
    }
    const sortingImpresions = () => {
        if (impresionSorted) {
            const sorted = data.sort((a, b) => (a.impressions) - (b.impressions))
            setImpresionSorted(false)
        } else {
            const sorted = data.sort((b, a) => (a.impressions) - (b.impressions))
            setImpresionSorted(true)
        }
    }

    const sortingPlacementId = () => {
        if (placementId) {
            const sorted = data.sort((a, b) => (a.placementId) - (b.placementId))
            setPlacementId(false)
        } else {
            const sorted = data.sort((b, a) => (a.placementId) - (b.placementId))
            setPlacementId(true)
        }
    }
    const sortingCountry = () => {
        if (countrySorted) {
            const sorted = data.sort((a, b) => {
                if (a.country < b.country) { return -1; }
                if (a.country > b.country) { return 1; }
                return 0;
            })

            setCountrySorted(false)
        } else {
            const sorted = data.sort((b, a) => {
                if (a.country < b.country) { return -1; }
                if (a.country > b.country) { return 1; }
                return 0;
            })
            setCountrySorted(true)
        }
    }
    const sortingCName = () => {
        if (siteNameSorted) {
            const sorted = data.sort((a, b) => {
                if (a.siteName < b.siteName) { return -1; }
                if (a.siteName > b.siteName) { return 1; }
                return 0;
            })

            setSiteNameSorted(false)
        } else {
            const sorted = data.sort((b, a) => {
                if (a.siteName < b.siteName) { return -1; }
                if (a.siteName > b.country) { return 1; }
                return 0;
            })
            setSiteNameSorted(true)
        }
    }
    const sortingSiteName = () => {
        if (siteNameSorted) {
            const sorted = data.sort((a, b) => {
                if (a.siteName < b.siteName) { return -1; }
                if (a.siteName > b.siteName) { return 1; }
                return 0;
            })

            setSiteNameSorted(false)
        } else {
            const sorted = data.sort((b, a) => {
                if (a.siteName < b.siteName) { return -1; }
                if (a.siteName > b.siteName) { return 1; }
                return 0;
            })
            setSiteNameSorted(true)
        }
    }
    const sortingPlacementName = () => {
        if (placementNameSorted) {
            const sorted = data.sort((a, b) => {
                if (a.placementName < b.placementName) { return -1; }
                if (a.placementName > b.placementName) { return 1; }
                return 0;
            })

            setPlacementNameSorted(false)
        } else {
            const sorted = data.sort((b, a) => {
                if (a.placementName < b.placementName) { return -1; }
                if (a.placementName > b.placementName) { return 1; }
                return 0;
            })
            setPlacementNameSorted(true)
        }
    }
    const sortingType = () => {
        if (typeSorted) {
            const sorted = data.sort((a, b) => {
                if (a.type < b.type) { return -1; }
                if (a.type > b.type) { return 1; }
                return 0;
            })

            setTypeSorted(false)
        } else {
            const sorted = data.sort((b, a) => {
                if (a.type < b.type) { return -1; }
                if (a.type > b.type) { return 1; }
                return 0;
            })
            setTypeSorted(true)
        }
    }

    const sortingDate = () => {
        if (dateSorted) {
            const sorted = data.sort((a, b) => new Date(b.date) - new Date(a.date))
            setDateSorted(false)
        } else {
            const sorted = data.sort((b, a) => new Date(b.date) - new Date(a.date))
            setDateSorted(true)
        }
    }
    //Pagination 
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    useEffect(() => {
        setDisplay(data.slice(0, 10))
    }, [data]);
    useEffect(() => {
        setDisplay(data.slice(indexOfFirstPost, indexOfLastPost))
    }, [dataSorted, ctrSorted, impresionSorted, countrySorted, dateSorted, typeSorted, placementId, placementNameSorted, siteNameSorted]);

// USEEFFECTS
    useEffect(() => {
        getdatafromfs()
    }, [])


    useEffect(() => {
        setDisplay(data.slice(indexOfFirstPost, indexOfLastPost))

    }, [currentPage]);



    if (scopes && scopes.find(element => element === 'reporting')) {
        return (<>
            <div className='reporting'>

                <table className='reporting__table'>
                    <thead className='reporting__table-thead'>
                        <tr className='reporting__table-thead-row'>
                            <th className='reporting__table-thead-row-th orange' colspan="10">
                                <div className="reporting__table-thead-row-th-filtersReporting">
                                    <div className="reporting__table-thead-row-th-filtersReporting-div">

                                        <select id="filter" name="filter" size="1" onChange={handleFilter}>
                                            {!isloaded ? <option >Cargando...</option> : <>
                                                <option>Seleccione una cuenta: </option>
                                                {aviableAcc.map((data) => (<>

                                                    <option value={data} >{data}</option>

                                                </>
                                                ))}</>}
                                        </select>
                                    </div>
                                    <div div className="reporting__table-thead-row-th-filtersReporting-div">
                                        {!isloaded ? <button className="buttonStandard" onClick={fetchReport} disabled >Buscar/Refresh</button> : <button className="buttonStandard" onClick={fetchReport}  >Buscar/Refresh</button>}
                                    </div>

                                </div>
                            </th>


                        </tr>

                        <tr className='reporting__table-thead-row'>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingCName}>Campaign Name</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingSiteName}>Site Name</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingPlacementName}>Placement Name</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingPlacementId}>Placement Id</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingDate}>Fecha</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingType}>Tipo</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingCountry}>Pais</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingImpresions}>Impresiones</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingClicks}>Clicks</th>
                            <th className='reporting__table-thead-row-th pointer' onClick={sortingCtr}>CTR</th>
                        </tr>
                    </thead>
                    <tbody className={isloaded ? 'reporting__table-body' : 'reporting__table-body tBodyCenter'}>
                        {!isloaded ? <>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>
                            <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                            </tr>

                        </>
                            :

                            data.length === 0 ?
                                <>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>
                                    <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                        <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102">Sin resultados...</td>
                                    </tr>

                                </>
                                : <Posts posts={display} loading={isloaded} />}


                    </tbody>


                </table>
                <Numberpages
                    postsPerPage={postsPerPage}
                    totalPosts={Math.floor(data.length / 10) + 1}
                    paginate={paginate}
                />
            </div>

        </>
        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />
        )
    }


}

export default CMReporting
