import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { getDocs, getFirestore, collection, getDoc, doc, updateDoc, onSnapshot, query } from "firebase/firestore";
import './index.scss'
import DisplayDatos from './DisplayDatos'
import { db } from '../../../Firebase';

const Profiles = () => {
  const { profileId } = useParams()
  const [user, setUser] = useState(null);
  const [datosDisplay, setDatosDisplay] = useState('datosGenerales');

  let fetchFS = async () => {
    const docRef = doc(db, "Empleados", profileId)
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    console.log(data);
    return data;
  }

  // const usuario = {
  //   nombre: 'Gonzalo',
  //   apellido: 'Vintrob',
  //   apodo: 'Gonza',
  //   area: 'Data',
  //   posicion: 'Data Scientist',
  //   emailB: ' gonzalo.vintrob@beyondmediaagency.com',
  //   ingreso: '01/06/2021',
  //   datosGenerales: {
  //     direccion: 'Av. Melian 2168, 2B',
  //     cumple: '12/02/2022',
  //     email: 'gonzalivintrovich@gmail.com',
  //     celular: '1132334212',
  //     alimentos: 'Vegano',
  //     dni: '36755652',
  //     cuil: '20-36755652-9',
  //     nacionalidad: 'Argentina'
  //   },
  //   datosBancarios: {
  //     banco: 'Banco Ciudad',
  //     cbu: '000421412312312412412421421421412',
  //     alias: 'gonzalo.vintrob',
  //     tipoCuenta: 'Cuenta Corriente'
  //   },
  //   datosOs: {
  //     os: 'OSDE',
  //     nroAfiliado: '000421412312312412412421421421412',
  //   }

  // }
  const fetchData = async function () {
    // const apiLink = 'https://randomuser.me/api/?results=1';
    // const response = await fetch(apiLink);
    // const data = await response.json();
    // const result = await data.results;
    let result = await fetchFS()
    setUser(result);
  }

  useEffect(() => {
    fetchData()
  }, []);
  return user ? (
    <div className='Profile'>
      {user && <>
        <div className='Profile__datosGenerales'>
          <div className='Profile__datosGenerales-img'>
            <img src="http://via.placeholder.com/600x600" alt="" />
          </div>
          <div className='Profile__datosGenerales-text'>
            <p><span>Nombre: </span>{user.nombre}</p>
            <p><span>Apellido: </span> {user.apellido}</p>
            {/* <p><span>Apodo: </span> {user.apodo}</p> */}
            <p><span>Email Beyond: </span>{user.mail_beyond}</p>
            <p><span>Ingreso: </span>{user.fecha_de_ingreso}</p>
            <p><span>Antiguedad: </span>8 meses</p>
            <p><span>Equipo: </span>{user.equipo}</p>
            <p><span>Área: </span>{user.area}</p>
            <p><span>Puesto: </span>{user.puesto}</p>
          </div>
        </div>
        <div div className='Profile__datosSensibles'>
          <div className='Profile__datosSensibles-buttons'>
            <p className={`Profile__datosSensibles-buttons-but${datosDisplay === 'datosGenerales' ? '-active' : ''}`} onClick={() => setDatosDisplay('datosGenerales')}>Datos Generales</p>
            <p className={`Profile__datosSensibles-buttons-but${datosDisplay === 'datosBancarios' ? '-active' : ''}`} onClick={() => setDatosDisplay('datosBancarios')}>Datos Bancarios</p>
            <p className={`Profile__datosSensibles-buttons-but${datosDisplay === 'datosOS' ? '-active' : ''}`} onClick={() => setDatosDisplay('datosOS')}>Datos Obra Social</p>
            <p className={`Profile__datosSensibles-buttons-but${datosDisplay === 'recibos' ? '-active' : ''}`} onClick={() => setDatosDisplay('recibos')}>Recibos</p>
            <p className={`Profile__datosSensibles-buttons-but${datosDisplay === 'permisos' ? '-active' : ''}`} onClick={() => setDatosDisplay('permisos')}>Permisos</p>
          </div>
          <DisplayDatos props={user} visual={datosDisplay} />
        </div>

      </>

      }
    </div>
  ) : null
}

export default Profiles