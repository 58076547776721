import React, { useContext } from 'react';
import ModalContext from '../../../Contexts/ModalContext';
import CustomImg from '../Imgs/customImg';
import IMAGES from '../../../Assets/Img';
import './index.scss'
import { X } from 'tabler-icons-react';
const ModalText = () => {
  const { setShowModal, text } = useContext(ModalContext)

  console.log(text[0])
  return (
    <>
      <div className='modalConteiner'>

        <div className='modalDiv'>
        <div className={'modalDiv__close'} onClick={() => setShowModal(false)}>
        <X size={50} strokeWidth={3} color={'#fafafa'}/>
            </div>
          <div className='modalDiv__figcaption'>
          <CustomImg {...IMAGES.comentario} clase={'modalDiv__figcaption-img'} />
          </div>
            <div className='textConteiner'>
            <p className='modalDiv__text'>
              {text.length > 1 ?  text :  text[0]}
            </p>

            </div>


        </div>
      </div>
    </>
  )


};

export default ModalText
