import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TablaOp from './TablaOp';
import './index.scss'
import Cookies from 'universal-cookie';
import AuthenticationContext from '../../Contexts/AuthConttext';
import { useNotifications } from '@mantine/notifications';
import { X } from 'tabler-icons-react';
import { Loader } from '@mantine/core';
const Ops = () => {
    const permisos = ['Devs', 'Administracion', 'Planning']
    const [moneda, setMoneda] = useState(`$AR`);
    const [op, setOp] = useState({
        anulaReemplaza: false,
        domicilio: "Ramsay 1968 - CABA",
        cuitAnunciante: "30-71522553-7",
        ops: [],
        moneda: moneda,
        invTot: 0
    });

    const [idCounter, setIdCounter] = useState(-1);
    const [isUploaded, setIsUploaded] = useState(true);

    const { scopes, email } = useContext(AuthenticationContext)
    const notifications = useNotifications();
    const dondeLePego = email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'

    const galletitas = new Cookies()
    const idToken = galletitas.get("id_token")
    const validateMail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    const cargarOP = (e) => {
        e.preventDefault()
        console.log(op);
    }
    const addOp = () => {
        const newOp = {
            id: idCounter + 1,
            costoBruto: 0,
            dias: {
                Enero: [],
                Febrero: [],
                Marzo: [],
                Abril: [],
                Mayo: [],
                Junio: [],
                Julio: [],
                Agosto: [],
                Septiembre: [],
                Octubre: [],
                Noviembre: [],
                Diciembre: []
            },
        }
        if (op.ops.length > 0) {
            setOp({ ...op, ops: [...op.ops, newOp] })

        } else {
            setOp({ ...op, ops: [newOp] })

        }
        setIdCounter(idCounter + 1)
    }

    const handlesumar = () => {
        return op.ops.reduce((acumulador, item) => {
            acumulador = parseFloat(acumulador)
            const add = acumulador += parseFloat(item.costoBruto)
            op.invTot = add
            return add
        }, 0);
    }


    useEffect(() => {
        console.log(op);
    }, [op]);

    const updateDays = (y, d) => {
        const isInOps = op.ops.find(x => x.id === y.id)
        console.log(isInOps);
        if (isInOps && op.ops.length > 0) {
            setOp({
                ...op,
                ops: [...op.ops.filter(x => x.id != y.id), { ...isInOps, dias: { ...d } }].sort(function (a, b) { return a.id - b.id; })
            })
        }
    }

    const handleInputChange = (event) => {
        setOp({
            ...op,
            [event.target.name]: event.target.value
        })
    }
    const refresh = (e, y) => {
        const isInOps = op.ops.find(x => x.id === y.id)
        if (isInOps && op.ops.length > 0) {
            setOp({
                ...op,
                ops: [...op.ops.filter(x => x.id != y.id), { ...isInOps, [e.target.name]: e.target.value }].sort(function (a, b) { return a.id - b.id; })
            })
        }
    }

    const borrarDatos = () => setOp({
        ...op,
        ops: []
    })


    const deleteData = (y) => {
        setOp({
            ...op,
            ops: [...op.ops.filter(x => x.id != y)]

        })
        console.log('si');
    }




    if (scopes && scopes.find(element => element === 'ops')) {
        return (
            <form className="ops__Conteiner" onSubmit={cargarOP}>

                <div className="ops__Conteiner-membrete">
                    <div className="ops__Conteiner-membrete-data">
                        <div className="ops__Conteiner-membrete-data-row">
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Proovedor</label>
                                <input className="ops__Conteiner-membrete-data-row-inputDiv-input" onInput={handleInputChange} name='proovedor' required value={op.proovedor}></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Anunciante</label>
                                <input className="ops__Conteiner-membrete-data-row-inputDiv-input" name='' placeholder='Digital Hive Group S.A.' required value={op.anunciante} disabled></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Campaña</label>
                                <input className="ops__Conteiner-membrete-data-row-inputDiv-input" onInput={handleInputChange} name='campaign' required value={op.campaign}></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Medio</label>
                                <input className="ops__Conteiner-membrete-data-row-inputDiv-input" onInput={handleInputChange} name='medio' required value={op.medio}></input>

                            </div>
                        </div>
                        <div className="ops__Conteiner-membrete-data-row">
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Domicilio</label>
                                <input type="text" className="ops__Conteiner-membrete-data-row-inputDiv-input" disabled name='' placeholder="Ramsay 1968 - CABA" value={op.domicilio} ></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>CUIT Anunciante</label>
                                <input type="text" disabled name='' placeholder="30-71522553-7" className="ops__Conteiner-membrete-data-row-inputDiv-input" value={op.cuitAnunciante}></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Producto</label>
                                <input type="text" className="ops__Conteiner-membrete-data-row-inputDiv-input" onInput={handleInputChange} name='producto' required value={op.producto}></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Marca</label>
                                <input type="text" className="ops__Conteiner-membrete-data-row-inputDiv-input" onInput={handleInputChange} required name='marca' value={op.marca} ></input>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                <label className='op__Conteiner-membrete-data-row-inputDiv-label'>Fecha</label>
                                <input type="date" className="ops__Conteiner-membrete-data-row-inputDiv-input" onInput={handleInputChange} required name='fecha' value={op.fecha} ></input>

                            </div>
                        </div>
                    </div>
                </div>



                {op.ops.length > 0 ? op.ops.map((data) => <TablaOp props={data} op={op} id={idCounter} refresh={refresh} deleteData={deleteData} updateDays={updateDays} />) : null}

                <div className='divButtonsOp'>
                    <p className='buttonStandard' onClick={borrarDatos}>Borrar Datos</p>
                    <p className='buttonStandard' onClick={addOp}>Agregar Datos</p>
                </div>
                <div className="ops__Conteiner-footer">
                    <div className="ops__Conteiner-footer-div-row">
                        <div className="ops__Conteiner-footer-div">
                            <div className='divinput'>
                                <label>Observaciones</label>
                                <textarea className="ops__Conteiner-footer-div-textarea" type="textarea" onInput={handleInputChange} name='observaciones' value={op.observaciones}></textarea>
                            </div>
                        </div>
                        <div className="ops__Conteiner-footer-div">
                            <div>
                                <div className='divinput'>
                                    <label>Forma de Pago</label>
                                    <input className="ops__Conteiner-footer-div-input" type="text" onInput={handleInputChange} name='formaDePago' value={op.formaDePago}></input>

                                </div>
                            </div>
                        </div>
                        <div className="ops__Conteiner-footer-div">
                            <div>
                                <p style={{ fontSize: '1.5rem' }}>Inversion Total: {handlesumar()} {moneda}</p>
                            </div>
                            <div>
                                <p>Moneda:</p>
                                <select defaultValue='$AR' onChange={(e) => setMoneda(e.target.value)}>
                                    <option value={'$AR'} >Pesos</option>
                                    <option value={'US$'} >Dolares</option>

                                </select>
                            </div>


                        </div>
                    </div>

                    {isUploaded ? <input type='submit' className='buttonStandard pointer' /> : <Loader color="orange" size="sm" />}
                </div>
            </form>
        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />
        )

    }
}

export default Ops
