import React, { useState, useContext, useEffect } from 'react'
import './index.scss'
import AuthenticationContext from '../../../Contexts/AuthConttext'
import ModalContext from '../../../Contexts/ModalContext'
import Modules from '../../../Components/Globals/NavBar/routes'
import Cookies from 'universal-cookie';
import { Loader } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
const SubirFacturasManual = () => {
    const { scopes, email } = useContext(AuthenticationContext)
    const { setText, setShowModal } = useContext(ModalContext)
    const [isUploaded, setIsUploaded] = useState(true)
    const [newFactura, setNewFactura] = useState({
        tipo_fac: '',
        rs: '',
        pv: '',
        nro_fac: '',
        fecha: '',
        subtotal: '',
        iva: '',
        periva: '',
        iibbcaba: '',
        iibbgba: '',
        total: '',
        descripcion: '',
        comentario: '',
    });
    const [letterBalue, setLetterBalue] = useState('');
    const [newPDF, setNewPDF] = useState(undefined);
    const permisos = Modules[2].subMenu[0].subMenu[0].roles
    
    const galletitas = new Cookies()
    const idToken = galletitas.get("id_token")
    const notifications = useNotifications();
    const dondeLePego  =email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'

    const toTimestamp = (strDate) => {  
        const dt = Date.parse(strDate);  
        return dt / 1000;  
      }  


    //FUNCTIONS
    function makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const subir = (e) => {
        e.preventDefault()
        const uuid = makeId(10);
        const target_2 = "https://adm.beyondmediaportal.com/facturacion/subir_factura_manual?data_type=text&file_id=" + uuid;
        const target_1 = "https://adm.beyondmediaportal.com/facturacion/subir_factura_manual?data_type=files&file_id=" + uuid;
        setIsUploaded(false)
        console.log(newFactura);
        const uploadData = new FormData();
        uploadData.append('x', newPDF);
        if (newPDF) {
            fetch(target_1, {
                method: "POST",
                mode: "cors",
                body: uploadData,
                headers: {
                    "idToken": idToken,
                    "agency": dondeLePego,
                },
                referrer: "localhost",
                referrerPolicy: "same-origin"
            }
            )
                .then(response =>{response.json()
                    if (response.status === 200) {
                        notifications.showNotification({
                            title: 'Documento cargado y enviado!!',
                            message: `El documento se subio exitosamente`,
                            color: 'green',
                            autoClose: 5000,
                        })
                        setIsUploaded(true)
                    } else {
                        notifications.showNotification({
                            title: 'Error en la carga',
                            message: `La el documento no fue cargado! `,
                            color: 'red',
                            autoClose: 5000,
                        })
                        setIsUploaded(true)
    
                    }})
                .then(data => {
                    if (data.response != "ok") {
                        console.log("error")
                        console.log(data)
                    } else {
                        fetch(target_2, {
                            method: "POST",
                            mode: "cors",
                            body: JSON.stringify(newFactura),
                            headers: {
                                "idToken": idToken,
                                "agency": dondeLePego
                            },
                            referrer: "localhost",
                            referrerPolicy: "same-origin"
                        })
                            .then(response => {response.json()
                            if (response.status === 200) {
                                notifications.showNotification({
                                    title: 'Factura cargada y enviada!!',
                                    message: `${response.response}`,
                                    color: 'green',
                                    autoClose: 5000,
                                })
                                setIsUploaded(true)
                            } else {
                                notifications.showNotification({
                                    title: 'Error en la carga',
                                    message: `La Factura no fue cargada! `,
                                    color: 'red',
                                    autoClose: 5000,
                                })
                                setIsUploaded(true)
            
                            }}
                            
                            )
                            .then(data => console.log(data))
                            .catch((e) => console.log(e))
                    }
                }
                )
                .catch((e) => console.log(e))

        } else {
            notifications.showNotification({
                title: 'Error en la carga',
                message: `No Cargo Ningun Documento! `,
                color: 'red',
                autoClose: 5000,
            })
            setIsUploaded(true)
        }

            console.log('si')
        }
        
        


    const handleSearchLetter = (e) => {
        setLetterBalue(e.target.value)

    }
    //USEEFFECTS
    useEffect(() => {
        newFactura.tipo_fac = letterBalue
    }, [letterBalue]);

    useEffect(() => {
        console.log(newPDF)
    }, [newPDF]);


    if ( scopes && scopes.find(element => element === 'subirFacturasManual')) {
        return (
            <div className='subirFacturasManual'>
                <form className='subirFacturasManual__form' onSubmit={subir}>
                    <fieldset className='subirFacturasManual__form-fieldset'>
                        <legend>Datos de la Factura</legend>
                        <div className="ops__Conteiner-membrete-data">
                            <div className="ops__Conteiner-membrete-data-row">
                                <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                    <label for="size_1">Tipo de Factura</label>
                                    <select value={letterBalue.value} onChange={handleSearchLetter} required>
                                        <option value=''>Seleccione tipo de factura</option>
                                        <option value="A">A</option>
                                        <option value="C">C</option>
                                        <option value="E">E</option>
                                        <option value="M">M</option>
                                        <option value="CREDITO">Nota de Credito</option>
                                        <option value="DEBITO">Nota de Debito</option>
                                    </select>
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                    <label for="size_2">Proovedor</label>
                                    <input required onInput={(e) => newFactura.rs = (e.target.value).toUpperCase()} type="text" name="size" id="size_2" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                    <label for="size_3">Punto de Venta</label>
                                    <input required onInput={(e) => newFactura.pv = e.target.value} type="text" name="size" id="size_3" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                    <label for="size_1">Nro de Factura</label>
                                    <input required onInput={(e) => newFactura.nro_fac = e.target.value} type="text" name="size" id="size_1" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_2">Fecha</label>
                                    <input required onInput={(e) => newFactura.fecha = toTimestamp(e.target.value)} type="date" name="size" id="size_2" placeholder='Formato:ej 31/1/2022' />
                                </div>

                            </div>
                            <div className="ops__Conteiner-membrete-data-row">
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_3">Subtotal</label>
                                    <input required onInput={(e) => newFactura.subtotal = `$${e.target.value}`} type="text" name="size" id="size_3" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                    <label for="size_1">Iva</label>
                                    <input required onInput={(e) => newFactura.iva = `$${e.target.value}`} type="text" name="size" id="size_1" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_2">Percep IVA</label>
                                    <input required onInput={(e) => newFactura.periva = `$${e.target.value}`} type="text" name="size" id="size_2" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_3">IIBBcaba</label>
                                    <input required onInput={(e) => newFactura.iibbcaba = `$${e.target.value}`} type="text" name="size" id="size_3" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv">
                                    <label for="size_1">IIBBgba</label>
                                    <input required onInput={(e) => newFactura.iibbgba = `$${e.target.value}`} type="text" name="size" id="size_1" />
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_2">Total</label>
                                    <input required onInput={(e) => newFactura.total = `$${e.target.value}`} type="text" name="size" id="size_2" />
                                </div>

                            </div>

                            <div className="ops__Conteiner-membrete-data-row">
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_3">Descripcion</label>
                                    <textarea onInput={(e) => newFactura.descripcion = e.target.value} cols="30" rows="10" required/>
                                </div>
                                <div className="ops__Conteiner-membrete-data-row-inputDiv"><label for="size_1">Comentario</label>
                                    <textarea onInput={(e) => newFactura.comentario = e.target.value} cols="30" rows="10" />
                                </div>
                            </div>

                        </div>
                        <form>
                            <div className='file-input  fit'>
                                <label for="size_2" >{!newPDF ? 'Seleccione Archivo' : newPDF.name}
                                    <input onChange={(e) => setNewPDF(e.target.files[0])} type="file" name="file" id="file" className='inputfile'accept="application/pdf" required       /></label>

                            </div>

                        </form>

                    </fieldset>
                    {isUploaded  ?<button className='buttonStandard'  > Subir Factura</button> : <Loader color="orange" size={`sm`} />}
                </form>

               



            </div>

        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />

        )
    }
}

export default SubirFacturasManual