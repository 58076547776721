import { useState, useEffect } from "react";
import { db } from "./Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Cookies from "universal-cookie";
import { doc, getDoc } from "firebase/firestore";






const useAuthListener = () => {

  const DELAY_TIME_SECONDS = 60;

  const [loggedIn, setLoggedIn] = useState(false);
  const cookies = new Cookies()

  const [checkingStatus, setCheckingStatus] = useState(true);
  const auth = getAuth()

  function __getIDtoken(user) {
    user.getIdToken().then(function (idToken) {
      cookies.set("id_token", idToken, { path: '/' })
    }).catch(function (error) {
      console.log("error en la auth.")
    });
  }

  function getUserIDtoken() {
    let user = auth.currentUser;
    __getIDtoken(user);
    // console.log("getting ID token.")
  }

  useEffect(() => {
    const interval = setInterval(
      getUserIDtoken,
      DELAY_TIME_SECONDS * 1000);

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {

    onAuthStateChanged(auth, (user) => {

      if (user) {

        setLoggedIn(true);

        const data = {
          "email": user.email,
          "name": user.displayName,
          "photo": user.photoURL,
          "uid": user.uid,
          "role": "",
          "createdAt": user.metadata.creationTime,
          "lastLogin": user.metadata.lastSignInTime
        }
        cookies.set("email", data.email)
        cookies.set("displayName", data.email)
        cookies.set("displayName", data.name)
        cookies.set("photoUrl", data.photo)
        cookies.set("loggedIn", true, { path: '/' })

        __getIDtoken(user)

        async function getData(email) {
          const roler = (await getDoc(doc(db, "Users", email))).data();
          return roler
        }
        getData(data.email).then((datata) => {
          const scopes = datata.tools;
          if (scopes == undefined) {
            scopes = "no_scope"
          }
          cookies.set("scopes", datata.tools)
        })


      }

      setCheckingStatus(false);
    });
  }, []);

  return { loggedIn, checkingStatus };
};
export default useAuthListener

