import React, { useState, useEffect } from 'react'
import './index.scss'
import ManagementCards from './ManagementCards'
import { Loader } from '@mantine/core';
import { Link } from 'react-router-dom';
import { getDocs, getFirestore, collection, getDoc, doc, updateDoc, onSnapshot, query } from "firebase/firestore";
import { db } from '../../Firebase';

const ManagementDos = () => {
    const [users, setUsers] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);

    let fetchFS = async () => {
        const querySnapshot = await getDocs(collection(db, "Empleados"));
        let docs = querySnapshot.docs;
        let _docs = docs.map(d => ({...d.data(), id: d.id}));
        return _docs;
    }

    const fetchData = async function () {
        let result = await fetchFS();
        setUsers([...result]);
    }

    useEffect(() => {

        fetchData()
    }, []);
    useEffect(() => {
        filterUsers()
    }, [userSearch]);

    const filterUsers = () => {
        userSearch == '' ? setFilteredUsers([]) :
            setFilteredUsers(users.filter((e) => ((e.nombre + " " + e.apellido).toLowerCase()).includes(userSearch)))
    }



    return (
        <div className='userManagement'>
            <div className='userManagement__buscador'>
                <label>
                    Buscar Empleado:
                    <input type="text" onChange={e => setUserSearch(e.target.value)} />
                </label>
            </div>
            <div className='userManagement__users'>
                {
                    users && filteredUsers.length == 0 && userSearch == '' ? users.map(x => <ManagementCards props={x} />) : (filteredUsers.length > 0 && userSearch != '' ? filteredUsers.map(x => <ManagementCards props={x} />) : 'No Results')
                }

            </div>




        </div>
    )
}

export default ManagementDos