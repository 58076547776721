import { useContext, useEffect } from "react"
import Cookies from "universal-cookie";
import AuthenticationContext from "./index"
import { db } from "../../Firebase";
import { doc, onSnapshot,getDoc } from "firebase/firestore";

function ContextUpdater() {
    const cookies = new Cookies();

    const {authenticated, setAuthenticated} = useContext(AuthenticationContext);
    const {usuario, setUsuario} = useContext(AuthenticationContext);
    const {email, setEmail} = useContext(AuthenticationContext);
    const {photo, setPhoto} = useContext(AuthenticationContext);
    const {idToken, setIdToken} = useContext(AuthenticationContext);
    const {scopes, setScopes} = useContext(AuthenticationContext);
    const {isloeaded, setIsloeaded} = useContext(AuthenticationContext);

            
    useEffect(()=> {
        // update parameters for context
        setAuthenticated(true);
        setUsuario(cookies.get("displayName"));
        setEmail(cookies.get("email"));
        setPhoto(cookies.get("photoUrl"));
        
        setIdToken(cookies.get("id_token"));
        setScopes(cookies.get("scopes"));
        
        setIsloeaded(true);
    }, [])



    
    return null 
}
export default ContextUpdater;