import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom"
const ManagementCards = ({props}) => {
  
  const sectionStyle = {
    // backgroundImage: `url(${props.picture.large})`,
  };
  return (
   
    <div className='ManagementCards'>
    <Link to={`${props.id}`}>
            {/* <div className='ManagementCards__avatar'>
                
            </div> */}
            <div class="hexagon" style={sectionStyle}>
            

            </div>
            <div className='ManagementCards__text'>
            <p>
             {`${(props.nombre + " " + props.apellido).toUpperCase()}`}
            </p>
            <p>
            {props.equipo}
            </p>
            <p>
            {props.mail_beyond.toUpperCase()}
            </p>
            </div>
            </Link>

    </div>
   
  )
}

export default ManagementCards