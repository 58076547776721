import React, {useContext, useEffect} from 'react';
import Cookies from 'universal-cookie';
import IMAGES from '../../../Assets/Img';
import CustomImg from '../../../Components/Common/Imgs/customImg';
import { Link } from 'react-router-dom';
import AuthenticationContext from '../../../Contexts/AuthConttext';
function Buttons({id, url, functi}) {


    const {email} = useContext(AuthenticationContext)


    const dondeLePego = email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
    const cookies = new Cookies()
    const idToken = cookies.get("id_token", "idToken", { path: '/' })
    return (
        <>
        <div className='divButt'>
        <div id="submit-to-cm" styles={{top: "100%", height: "100%"}} >
            <form action={`https://api.beyondmediaportal.com/gapi/cb_web/upload-campaign` }method="GET" >
                <input type="hidden" name="id_token" value={cookies.get("id_token")}></input>
                <input type="hidden" name="documentId" value={id}></input>
                <input type="hidden" name="agency" value={dondeLePego}></input>
                <input type="submit" value="Subir cambios a CM" className='buttonStandard'></input>
            
        </form>
            
            <a href={url} target="_blank" className='buttonStandard'>CB Sheets</a>
            <Link to={`/webcb`} className='buttonIcon'><CustomImg {...IMAGES.volverAtras} /></Link>
        </div>
        </div>
        </>
    )
}
export default Buttons
