import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import "./index.scss"
import { getDocs, getFirestore, collection, query, onSnapshot } from "firebase/firestore";
import Modules from '../../Components/Globals/NavBar/routes';
import Cookies from 'universal-cookie';
import AuthenticationContext from '../../Contexts/AuthConttext';
import { db } from '../../Firebase';
import Numberpages from '../../Components/Common/Numberpages';
import { Loader } from '@mantine/core';
const WebCB = () => {
    const cookies = new Cookies();
    const { scopes, email } = useContext(AuthenticationContext)
    const permisos = Modules[1].subMenu[0].subMenu[0].roles
    const dondeLePego = email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
    const idToken = cookies.get("id_token", "idToken", { path: '/' })
    const [state, setState] = useState([])
    const [display, setDisplay] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)
    const [showclosed, setshowclosed] = useState(false)
    const [filteredCB, setfilteredCB] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(20);
    //Pagination 
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;


    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    function handleShow() {
        if (!showclosed) {
            setshowclosed(true)
        } else {
            setshowclosed(false)
        }

    }
    useEffect(() => {
        getdatafromfs()
    }, [])

    useEffect(() => {
        setDisplay(filteredCB)
    }, [filteredCB]);





    async function getdatafromfs() {

        if (dondeLePego === 'BEYOND') {
            const q = query(collection(db, "CBs"))
            const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {

                const newCB = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                let filtered = querySnapshot.docs
                    .map(doc => ({ ...doc.data(), id: doc.id }))
                    .sort((a, b) => (new Date(b.CreationDate) - new Date(a.CreationDate)));
                // setfilteredCB(filtered.filter(x => x.Status !== 'closed'))
                setfilteredCB(filtered)
                // const source = querySnapshot.metadata.fromCache ? "local cache" : "server";
                setState(newCB);
                setIsLoaded(true)
            });

        } else {
            const q = query(collection(db, "CBsDigital"))
            const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {

                const newCB = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                const source = querySnapshot.metadata.fromCache ? "local cache" : "server";
                const filtered = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                // setfilteredCB(filtered.filter(x => x.Status !== 'closed'))
                setfilteredCB(filtered)

                console.log("Data came from " + source);
                setState(newCB);
                setIsLoaded(true)
            });



        }

    }

    // useEffect(() => {
    //     // setDisplay(!showclosed ? filteredCB.slice(indexOfFirstPost, indexOfLastPost) : state.slice(indexOfFirstPost, indexOfLastPost))


    //     // setDisplay(!showclosed ? [...filteredCB].filter(x => x.Status !== 'closed') : filteredCB);
    // }, [showclosed]);

    // useEffect(() => {
    //     setDisplay(!showclosed ? filteredCB.slice(indexOfFirstPost, indexOfLastPost) : state.slice(indexOfFirstPost, indexOfLastPost))

    // }, [currentPage]);
    if (scopes && scopes.find(element => element === 'campaignBuilder')) {
        return (
            <div className='CBConteiner'>

                <table className='CBConteiner__table'>
                    <thead className='CBConteiner__table-thead'>
                        <tr className='CBConteiner__table-thead-row'>
                            <th className='CBConteiner__table-thead-row-th'>CB</th>
                            <th className='CBConteiner__table-thead-row-th'>Última actualización</th>
                            <th className='CBConteiner__table-thead-row-th'>
                                <label class="toggle" for="uniqueID">

                                    <input type="checkbox" class="toggle__input" id="uniqueID" onChange={handleShow} value={showclosed} />
                                    <span class="toggle-track">
                                        <span class="toggle-indicator">

                                            <span class="checkMark">
                                                <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                                                    <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </span>
                                    CBs cerrados

                                </label>

                            </th>
                        </tr>
                    </thead>

                    <tbody
                        className={isLoaded
                            ? 'CBConteiner__table-body'
                            : 'CBConteiner__table-body tBodyCenter'}>
                        {!isLoaded
                            ? <>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>
                                <tr className='verFacturasConteiner__listaFacturas-table-body-row'>
                                    <td className='verFacturasConteiner__listaFacturas-table-body-row-td' colspan="102"><Loader color="orange" size="sm" /></td>
                                </tr>

                            </>
                            : (
                                display.map((key) => (
                                    <tr className='CBConteiner__table-body-row' key={key.id}>
                                        <td className='CBConteiner__table-body-row-td'>
                                            <Link to={`/webcb/doc/${key.id}`}>{key.CBName}</Link>
                                        </td>
                                        <td className='CBConteiner__table-body-row-td'>{`${key.CreationDate} por ${key.Owner}`}</td>
                                        <td className='CBConteiner__table-body-row-td'>
                                            {key.Status}</td>
                                    </tr>)
                                )

                            )
                        }
                    </tbody>

                </table>
                {/* <Numberpages
                    postsPerPage={postsPerPage}
                    totalPosts={!isLoaded ? Math.floor(filteredCB.length / 10) + 1 : Math.floor(state.length / 10) + 1}
                    paginate={paginate}
                /> */}
            </div>
        )
    } else {
        return (
            <Loader color="orange" size={`xl`} />
        )
    }

}

export default WebCB
