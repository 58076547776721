import { createContext,useState,useEffect } from "react";


const ModalContext = createContext()

const ModalProvider =({children}) =>{
    const [showModal, setShowModal] = useState(false);
    const [text, setText] = useState('');


    return(<ModalContext.Provider value={{showModal, setShowModal,text, setText}}>{children}</ModalContext.Provider>)
}
export {ModalProvider}
export default ModalContext

