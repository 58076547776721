import { useParams } from "react-router-dom"
import { useState, useEffect } from 'react';
import Buttons from './Buttons';
import Log from "./Log";
import Site from "./Site";
import { useFirebaseApp } from "reactfire";
import { doc, getDoc, getDocs, getFirestore, collection, updateDoc, writeBatch } from "firebase/firestore";
import Cookies from 'universal-cookie';
import { Loader } from '@mantine/core';

function Document({ optional_id }) {
    const [isLoading, changeLoading] = useState(true)
    const [cid, changecid] = useState("loading...")
    const [pid, changepid] = useState("loading...")
    const [cbn, changecbn] = useState("loading...")
    const [status, changestatus] = useState("loading...")
    const [placementstodelete, changeplacementstodelete] = useState([])
    const [editmode, changeeditmode] = useState(false)
    const [logs, updatelogs] = useState([{ "exceptions": "", "ratio": "", "start": "", "time": "", "total_uploaded": "", "uploaded_by": "" }])
    const [row, changerow] = useState({ Compra: "", Cost: "", Drive: "", EndDate: "", PLName: "", PackName: "", PackageId: "", PlacementId: "", Row: "", SiteId: "", SiteName: "", Size: "", StartDate: "", Status: "", Type: "", Units: "", Url: "", docId: "" })
    const [alldocs, changealldocs] = useState({})
    const [sheetsUrl, setSheetsUrl] = useState("URLn't")
    const cookies = new Cookies();
    const idToken = cookies.get("id_token", "token", { path: '/' })
    // let relojito
    // const timer = () =>{
    //     relojito = setInterval(() => {
    //         getProcess()
    //         console.log('disparando')
    //     }, 1000);
    // }

    const obbbbj = {}
    function toggleEditRow(roww) {
        window.document.getElementById("modal").style.display = "flex"
        window.document.body.style.overflow = "hidden"
        changerow(alldocs[roww])

    }

    function ordenator(objeto) {
        let objfinal = { metadata: {}, rows: { sites: {} } }
        objfinal.metadata = objeto.metadata

        objeto.arr.forEach(function (each) {
            obbbbj[each.docId] = each
            let siteId = each.SiteId
            let packname = each.PackName
            let name = each.PLName
            if (Object.keys(objfinal.rows.sites).indexOf(siteId) === -1) {

                objfinal.rows.sites[siteId] = { [packname]: { placements: { [name]: each } } }

            }
            else if (Object.keys(objfinal.rows.sites[siteId]).indexOf(packname) === -1) {
                objfinal.rows.sites[siteId][packname] = { placements: { [name]: each } }

            }
            else {
                objfinal.rows.sites[siteId][packname].placements[name] = each
            }
        })
        changealldocs(obbbbj)
        return objfinal
    }
    function handlePlacementsCallback(childData) {
        let newplacements = placementstodelete
        if (placementstodelete.indexOf(childData) === -1) {
            newplacements.push(childData)
            changeplacementstodelete(newplacements)
        }
        else {
            newplacements.splice(newplacements.indexOf(childData), 1)
            changeplacementstodelete(newplacements)
        }

    }
    const { id } = useParams()
    let [state, setState] = useState({ "rows": { "sites": {} }, "metadata": { "CBName": "", "Owner": "", "Status": "", "CreationDate": "", "CampaignId": "", "AdvertiserId": "", "ProfileId": "" } })

    const firebase = useFirebaseApp()
    const fs = getFirestore(firebase)
    async function changedata(id, data) {
        const ref = doc(fs, "CBs/" + id)
        await updateDoc(ref, data)
    }
    async function getdata() {
        const docRef = doc(fs, "CBs/" + id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data());
        let metadata = {}
        if (docSnap.exists()) {
            metadata = docSnap.data();
        } else {
            console.log("No such document!");
        }
        const querySnapshot = await getDocs(collection(fs, "CBs/" + id + "/filas"));
        const stArray = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            stArray.push(data)
        });
        let stateObj = { metadata: metadata, arr: stArray }
        return stateObj
    }
    
    async function getProcess() {
        const docRef = doc(fs, "CBs/" + id);
        const docSnap = await getDoc(docRef);
        const data =docSnap.data()
        const process = data.Process
        console.log(process)
    }

    async function getlogs() {
        const querySnapshot = await getDocs(collection(fs, "CBs/" + id + "/logs"));
        const logsarr = []

        querySnapshot.forEach((doc) => {
            let data = doc.data()
            logsarr.push(data)
        });

        return logsarr
    }

    useEffect(() => {
        getdata().then(function (data) {

            let ordenado = ordenator(data)
            setState(ordenado)
            changecid(ordenado.metadata.CampaignId)
            changestatus(ordenado.metadata.Status)
            changepid(ordenado.metadata.ProfileId)
            changecbn(ordenado.metadata.CBName)
            setSheetsUrl(ordenado.metadata.SheetsUrl)
            changeLoading(false)
        })
        getlogs().then(function (data) {
            let loggs = data.sort(function (a, b) { return b.start - a.start });
            for (let i = 0; i < loggs.length; i++) {
                loggs[i].number = loggs.length - i;
            }
            updatelogs(loggs)

        })


    }, [])

    let array = []
    for (let key in state.rows.sites) {
        array.push(key)
    }
    async function changeCID(e) {
        e.preventDefault()
        await changedata(id, { CampaignId: cid })
    }
    async function changePID(e) {
        e.preventDefault()
        await changedata(id, { ProfileId: pid })
    }
    async function changeSTATUS(e) {
        e.preventDefault()
        await changedata(id, { Status: status })
    }
    async function changeCBN(e) {
        e.preventDefault()
        await changedata(id, { CBName: cbn })
    }
    async function deletePlacements(e) {
        console.log(placementstodelete)
        const querySnapshot = await getDocs(collection(fs, "CBs/" + id + "/filas"))
        const batch = writeBatch(fs)

        if (placementstodelete.length == 0) {
            if (window.confirm('This will delete all placements')) {
                querySnapshot.forEach(function (each) {
                    let data = each.data()
                    batch.delete(doc(fs, "CBs/" + id + "/filas/" + data.docId))
                })

            }

        }
        querySnapshot.forEach(function (each) {
            let data = each.data()
            if (placementstodelete.indexOf(data.docId) !== -1) {
                batch.delete(doc(fs, "CBs/" + id + "/filas/" + data.docId))
            }
        })

        await batch.commit();
        window.location.reload(false)
    }

    window.document.addEventListener('keypress', function (e) {
        console.log(e.key)
        window.document.getElementById("modal").style.display = "none"
    })
    if (!isLoading) {

        return (

            <div className='CBConteiner'>
                <div id="modal" style={{ backgroundColor: "rgba(0,0,0,0.4)", display: "none", height: "100vh", position: "absolute", zIndex: "1000", width: "100vw", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ height: "auto", backgroundColor: "rgb(32, 74, 141)" }}>
                        <button style={{ textAlign: "right" }} onClick={() => {
                            window.document.getElementById("modal").style.display = "none"
                            document.body.style.overflow = 'visible';
                        }}>x</button>
                        <form>
                            <input type="text" value={row.PLName} onChange={() => console.log("change")}></input>
                            <input type="text" value={row.Type} onChange={() => console.log("change")}></input>
                            <input type="text" value={row.StartDate} onChange={() => console.log("change")}></input>
                            <input type="text" value={row.Drive} onChange={() => console.log("change")}></input>
                            <input type="text" value={row.Size} onChange={() => console.log("change")}></input>
                        </form>
                        <p>Edit placement</p>
                    </div>
                </div>
                <div style={{ fontFamily: "monospace", fontSize: "large" }} className='CBConteiner-div'>

                    <table id="html-table" className='CBConteiner__tablePlacement' >
                        <thead id="parent" className='CBConteiner__tablePlacement-thead'>
                            <tr className='CBConteiner__tablePlacement-thead-row' >
                                <th className='CBConteiner__tablePlacement-thead-row-th' onClick={getProcess}>Site ID</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Package</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Package ID</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Placement</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Type</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Size</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Start Date</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>End Date</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Landing URL</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th' >Drive URL</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Placem. ID</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Status</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>
                                    <button onClick={() => { if (window.confirm('Are you sure?')) deletePlacements() }}>Delete</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody  className='CBConteiner__tablePlacement-body'>
                        {array.map((key) => {
                            if (key !== "rows" && key !== "metadata") {
                                let sitename = ""
                                return (
                                    <Site toggleEditRow={toggleEditRow} name={key} siteName={sitename} packs={state.rows.sites[key]} campaignId={state["metadata"]["CampaignId"]} profileId={state["metadata"]["ProfileId"]} advertiserName={state["metadata"]["AdvertiserName"]} parentCallback={handlePlacementsCallback} edit={editmode} key={key} documentId={id} />
                                )
                            }
                            else return null
                            
                        })}
                            </tbody>

                    </table>
                    <table  className='CBConteiner__tablePlacement'>
                        <thead className='CBConteiner__tablePlacement-thead'>

                            <tr className='CBConteiner__tablePlacement-thead-row' >
                                <th className='CBConteiner__tablePlacement-thead-row-th'>CB</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Last updated by</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Last updated at</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Status</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Campaign ID</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Advertiser ID</th>
                                <th className='CBConteiner__tablePlacement-thead-row-th'>Profile ID</th>
                            </tr>
                        </thead>
                        <tbody key={"tbody-footer-key"} className='CBConteiner__tablePlacement-body'>
                            <tr className='CBConteiner__tablePlacement-body-row'>
                                <td className='CBConteiner__tablePlacement-body-row-td'>
                                    <form onSubmit={changeCBN}>


                                        <input type="text" onChange={e => changecbn(e.target.value)} value={cbn}></input>
                                        <input type="submit" style={{ position: "absolute", left: "-9999px", width: "1px", height: "1px" }} tabIndex="-1" value="edit"></input>
                                    </form>

                                </td>
                                <td className='CBConteiner__tablePlacement-body-row-td'>
                                    {state["metadata"]["Owner"]}
                                </td>
                                <td className='CBConteiner__tablePlacement-body-row-td'>
                                    {state["metadata"]["CreationDate"]}
                                </td>
                                <td className='CBConteiner__tablePlacement-body-row-td center'>
                                    <form onSubmit={changeSTATUS}>

                                        <select onChange={e => changestatus(e.target.value)}  value={status}>
                                            <option value="open">Open</option>
                                            <option value="closed">Closed</option>
                                            <option value="pending">Pending</option>
                                        </select>
                                        <input type="submit" value="edit" className="buttonStandard"></input>
                                    </form>

                                    
                                </td>
                                <td className='CBConteiner__tablePlacement-body-row-td'>
                                    <form onSubmit={changeCID}>
                                        <input type="text" onChange={e => changecid(e.target.value)}  value={cid}></input>
                                        <input type="submit" style={{ position: "absolute", left: "-9999px", width: "1px", height: "1px" }} tabIndex="-1" value="edit"></input>
                                    </form>
                                </td>
                                <td className='CBConteiner__tablePlacement-body-row-td'>
                                    {state["metadata"]["AdvertiserId"]}
                                </td>
                                <td className='CBConteiner__tablePlacement-body-row-td'>
                                    <form onSubmit={changePID}>
                                        <input type="text" onChange={e => changepid(e.target.value)}  value={pid}></input>
                                        <input type="submit" style={{ position: "absolute", left: "-9999px", width: "1px", height: "1px" }} tabIndex="-1" value="edit" ></input>
                                    </form>
                                </td>


                            </tr>
                        </tbody>

                    </table>
                    <div id="divLogs">
                        <Log documentId={id} data={logs} />
                        <Buttons id={id} url={sheetsUrl} functi={getProcess}/>

                    </div>



                </div>
            </div>
        );
    }
    else {
        return <Loader color="orange" size={`xl`} />
    }


}

export default Document;
