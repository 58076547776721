import React, { useEffect, useState } from 'react';
import ModalDays from './ModalDays'
import { X } from 'tabler-icons-react';

const TablaOp = ({ props, ops, id, refresh, deleteData,updateDays }) => {
    const [showModal, setShowModal] = useState(false);
    const [thisOp, setThisOp] = useState({
        id: id,
        
    });







    const handleModal = () => showModal ? setShowModal(false) : setShowModal(true)
    return (<>
        <div className="ops__Conteiner-info-table-conteiner" key={id}>
            <div className="ops__Conteiner-info-table">
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Ubicacion</label>
                    <input type="text" className="ops__Conteiner-info-table-row-data-input" name={'ubicacion'} onChange={(e) =>refresh(e,props)} required value={props.ubicacion}></input>
                </div>
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Sitios</label>
                    <input type="text" className="ops__Conteiner-info-table-row-data-input" name={'sitios'} onChange={(e) =>refresh(e,props)} required value={props.sitios}></input>
                </div>
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Detalle</label>
                    <input type="text" className="ops__Conteiner-info-table-row-data-input" name={'detalle'} onChange={(e) =>refresh(e,props)} required value={props.detalle}></input>
                </div>
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Device</label>
                    <input type="text" className="ops__Conteiner-info-table-row-data-input" name={'device'} onChange={(e) =>refresh(e,props)} required value={props.device}></input>
                </div>
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Costo Unitario</label>
                    <input type="number" className="ops__Conteiner-info-table-row-data-input" name={'cpmBruto'} onChange={(e) =>refresh(e,props)} required value={props.cpmBruto}></input>
                </div>
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Descuentos</label>
                    <input type="number" className="ops__Conteiner-info-table-row-data-input" name={'descuentos'} onChange={(e) =>refresh(e,props)} required value={props.descuentos}></input>
                </div>
                <div className="ops__Conteiner-info-table-row-data-inputDiv">
                    <label>Inversion</label>
                    <input type="number" className="ops__Conteiner-info-table-row-data-input" defaultValue={0} name={'costoBruto'} onChange={(e) =>refresh(e,props)} required value={props.costoBruto}></input>
                </div>
            </div>
            <div className="ops__Conteiner-info-table-days">
                <p  className="buttonStandard" onClick={handleModal}>Seleccionar Dias</p>
            </div>
            <X
                                        size={60}
                                        strokeWidth={3}
                                        color={'red'}
                                        onClick={() => deleteData(props.id)}
                                    />
        </div>

        {showModal ? <ModalDays handleShow={handleModal} props={props} days={props.dias} updateDays={updateDays} id={id}/> : null}
    </>
    )
}

export default TablaOp
