import { createContext,useState,useEffect } from "react";
import Cookies from "universal-cookie";
import { db } from "../../Firebase";
import { getDoc, where, doc ,query,collection,onSnapshot} from "firebase/firestore";

const AuthenticationContext = createContext()

const AuthenticationProvider =({children, values}) => {
    const cookies = new Cookies();
  
    const [authenticated, setAuthenticated] = useState(true)
    const [usuario, setUsuario] = useState(cookies.get("displayName"))
    const [email, setEmail] = useState(cookies.get("email"))
    const [photo, setPhoto] = useState(cookies.get("photoUrl"))

    const [idToken, setIdToken] = useState(cookies.get("id_token"))
    
    const [isloeaded, setIsloeaded] = useState(true);
    const [scopes, setScopes] = useState([]);
    const [loadedNav, setLoadedNav] = useState(false);

    // async function getUserFs2() {
    //     let q
    //     if (email){
    //         q = query(collection(doc(db, "Users", email)))
    //     }

    //     const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
    //         const datita = querySnapshot.docs.map((doc) => (doc.data()))
    //     setScopes([...datita.tools])
    //     })
    //   }
      

    async function getUserFs2() {
        const docRef = doc(db, "Users", email)
        const docSnap = await getDoc(docRef);
        const getData = docSnap.data()
        setScopes(getData.tools)
      
      }
      useEffect(() => {
          if (scopes && scopes.length > 0) {
            cookies.set("scopes", scopes)
            setLoadedNav(true)
          }
        //   console.log(scopes);
      }, [scopes]);
      
    useEffect(() => {
        if (email) {
            if (!scopes) {
                getUserFs2()
            } 
        }
        
    }, [email]);
    useEffect(() => {
        if (email) {
            if (!scopes) {
                getUserFs2()
            } 
        }
        
    }, []);





    return(<AuthenticationContext.Provider value={{isloeaded, loadedNav,setIsloeaded,photo,idToken, setIdToken, setPhoto,email, setEmail,authenticated,setUsuario, usuario,setAuthenticated,scopes,setScopes}}>{children}</AuthenticationContext.Provider>)
}

export {AuthenticationProvider}
export default AuthenticationContext