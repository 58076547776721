import React,{useState,useEffect} from 'react'
import Modal from './Modal'
import './index.css'
import IMAGES from '../../Assets/Img';
import CustomImg from '../../Components/Common/Imgs/customImg';
const Creatividades = () => {
    const [modal, setModal] = useState(false);
    const [creativity, setCreativity] = useState(undefined);
    const handleModal = ()=>setModal(!modal)
    useEffect(() => {
        creativity && (document.getElementById('creativeCode').innerHTML = creativity)
    }, [creativity]);
  return (
   <>
   <div className="conteiner">
        <div className="hero">
        <CustomImg {...IMAGES.teco} clase={"navBar__header-logoCol"} />
        </div>
        <div className="contenido">
           
            <div id="creativeCode">
                
                   </div>

                <button id="prev" className="buttonStandard" onClick={handleModal}>Insertar Iframe</button>

            </div>
        </div>
        {modal && <Modal handlemodal={handleModal} fn={setCreativity}/>  }
   </>
  )
}

export default Creatividades