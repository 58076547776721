import React,{useEffect,useContext, useState} from 'react';
import IMAGES from '../../../../Assets/Img';
import CustomImg from '../../../../Components/Common/Imgs/customImg';
import AuthenticationContext from '../../../../Contexts/AuthConttext';
import ModalContext from '../../../../Contexts/ModalContext';
import Cookies from 'universal-cookie';
import { FileDownload, FileUpload ,MessageShare, FileDescription,Check } from 'tabler-icons-react';
const Posts = ({ posts, loading,openCommentModal, fna, fn ,arr}) => {
  const { email,scopes } = useContext(AuthenticationContext)
  const cookies = new Cookies();
  const dondeLePego  =email && email.includes('beyond') ? 'BEYOND' : 'DIGITAL'
  const idToken = cookies.get("id_token", "idToken", { path: '/' })
  const { setShowModal, setText } = useContext(ModalContext)


  
//FUNCTIONS
  const openModal = (texto) => {
      setText(texto)
          ; setShowModal(true)
  }
    if (!loading) {
      return <h2>Loading...</h2>;
    }else{
      return(<>
          {posts.map((data) => (
                                          <tr  key={data.id} className='verFacturasConteiner__listaFacturas-table-body-row'>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{data.items.tipo_fac}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{data.items.rs}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{data.items.pv}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{data.items.nro_fac}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{`${(data.items.fecha).getDate()}/${(data.items.fecha).getMonth()+1}/${(data.items.fecha).getFullYear()}`}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >${data.items.subtotal}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{data.items.iva}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >${data.items.periva}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >${data.items.iibbcaba}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >${data.items.iibbgba}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >${data.items.total}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  > <div className='divLupita pointer' onClick={() => openModal([data.items.descripcion])} ><FileDescription size={26} strokeWidth={1.5} color={'#fafafa'}/></div></td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  > <span className='divLupita pointer' onClick={() => openCommentModal(data.id, data.items.comentarios)}><MessageShare size={26} strokeWidth={1.5} color={'#fafafa'}/></span></td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'>{( scopes && scopes.find(element => element === 'autorizarPagos')) &&<div className='circleStatus pointer'><div>{!data.pago && <button  onClick={()=>fna(data.id, data.autorizado,  data.gs_name )} className="buttonCheck">{data.autorizado ? <Check size={18} strokeWidth={3} color={'#FFB60B'}/> : ''}</button> } </div></div>}</td>
                                              <td className={`verFacturasConteiner__listaFacturas-table-body-row-td  `} ><div className='circleStatus'><div className={`circleStatus${data.pago && data.autorizado ? '-pago' : (data.autorizado ? '-autorizado' :'-impago')}`} title={data.pago && data.autorizado ? 'Paga' : (data.autorizado ? 'Autorizada para pagar' :'Impaga')}></div></div></td>
                                              <td className={`verFacturasConteiner__listaFacturas-table-body-row-td  `} >{( scopes && scopes.find(element => element === 'cargarPagos')) &&<div className='circleStatus pointer'><div>{data.autorizado && !data.pago ? (<button  onClick={()=>fn(data.id, data.gs_name )} className="buttonCheck">{arr.find(i => (i === data.gs_name)) ? <Check size={18} strokeWidth={3} color={'#FFB60B'}/> : ''}</button>  ) : null}</div></div>}</td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  ><a href={`https://adm.beyondmediaportal.com/facturacion/download?filename=${data.gs_name}&id_token=${idToken}&agency=${dondeLePego}`} onClick={() => console.log(`https://adm.beyondmediaportal.com/facturacion/download?filename=${data.gs_name}&id_token=${idToken}&agency=${dondeLePego}`)} target="_blank" rel="noopener noreferrer"><FileDownload size={26} strokeWidth={1.5} color={'#fafafa'}/></a></td>
                                              <td className='verFacturasConteiner__listaFacturas-table-body-row-td'  >{data.pago && <a href={`https://adm.beyondmediaportal.com/facturacion/descargar_comprobante?beyond_orden_de_pago=${data.beyond_orden_de_pago}&id_token=${idToken}&agency=${dondeLePego}`}  target="_blank" rel="noopener noreferrer"><FileDownload size={26} strokeWidth={1.5} color={'#fafafa'}/></a>}</td>
                                          </tr>)
                                      )}
      </>)


    }

  
};

export default Posts;