import React, { useState, useEffect } from 'react'
import { getDocs, getFirestore, collection, getDoc, doc, updateDoc, onSnapshot, query } from "firebase/firestore";
import { db } from '../../../../Firebase';
import { Edit } from 'tabler-icons-react';
import { ManagementEmbed } from '../../../Management';



const DisplayDatos = ({ props, visual }) => {
    const [aviableScopes, setAviableScopes] = useState([]);
    async function getScopes() {
        const docRef = doc(db, "Scopes", 'root')
        const docSnap = await getDoc(docRef);
        const getData = docSnap.data()
        setAviableScopes(getData.scopes)
    }

    useEffect(() => {
        getScopes()
    }, []);

    switch (visual) {
        case 'datosGenerales':
            return (
                <div className='Profile__datosSensibles-display'>
                    <div className='Profile__datosSensibles-display-dG'>
                        <p><span>DNI: </span>{props.dni}</p>
                        <p><span>CUIL: </span>{props.cuil}</p>
                        <p><span>Email Personal: </span>{props.email_personal}</p>
                        <p><span>Nacionalidad: </span>{props.pais}</p>
                        <p><span>Telefono Celular: </span>{props.Celular}</p>
                        <p><span>Fecha de nacimiento: </span>{props.fecha_de_nacimiento}</p>
                        <p><span>Direccion: </span>{props.Direccion}</p>
                        <p><span>Especificacion Alimentaria: </span>{props.preferencia_alimenticia}</p>
                    </div>
                    <div className='Profile__datosSensibles-display-edit'>
                        <Edit
                            size={60}
                            strokeWidth={2}
                            color={'#fafafa'}
                        />
                    </div>

                </div>
            )
        case 'datosBancarios':
            return (
                <div className='Profile__datosSensibles-display'>
                    <div className='Profile__datosSensibles-display-dG'>
                        <p><span>Banco: </span>{props.banco}</p>
                        <p><span>CBU: </span>{props.cbu}</p>
                        <p><span>Alias: </span>{props.alias}</p>
                        <p><span>Tipo de Cuenta </span>{props.tipo_cuenta}</p>
                    </div>
                    <div className='Profile__datosSensibles-display-edit'>
                        <Edit
                            size={60}
                            strokeWidth={2}
                            color={'#fafafa'}
                        />
                    </div>
                </div>
            )
        case 'datosOS':
            return (
                <div className='Profile__datosSensibles-display'>
                    <div className='Profile__datosSensibles-display-dG'>
                        <p><span>Obra Social/Prepaga: </span>{props.obra_social}</p>
                        <p><span>Numero de Afiliado: </span>{props.nro_afiliado}</p>
                    </div>
                    <div className='Profile__datosSensibles-display-edit'>
                        <Edit
                            size={60}
                            strokeWidth={2}
                            color={'#fafafa'}
                        />
                    </div>
                </div>
            )
        case 'permisos':
            return (
                <ManagementEmbed mail_beyond={props.mail_beyond}/>
            )
        default:
            return (
                <div className='Profile__datosSensibles-display'>
                </div>
            )

    }

}

export default DisplayDatos